import AdminUseCase from 'domain/usecase/admin/AdminUseCase'
import { RestaurantFoodFeedback, RestaurantFoodFeedbackStatus } from 'grpc/gen/admin/v1/admin'
import { makeObservable, observable, runInAction } from 'mobx'

export default class HomeListViewModel {
	@observable public feedbacks: RestaurantFoodFeedback[]
	@observable public isLoading: boolean
	@observable public hasMore: boolean

	private adminUseCase: AdminUseCase

	private page: number
	private pageSize: number

	public constructor(adminUseCase: AdminUseCase) {
		this.feedbacks = []
		this.isLoading = false
		this.hasMore = true

		this.page = 1
		this.pageSize = 10

		this.adminUseCase = adminUseCase
		makeObservable(this)
	}

	public async fetchData() {
		const feedbacks = await this.adminUseCase.getRestaurantFoodFeedback(
			RestaurantFoodFeedbackStatus.REST_FOOD_FEEDBACK_STATUS_ACCEPTED,
			null,
			this.page,
			this.pageSize,
		)
		runInAction(() => {
			if (feedbacks.length < this.pageSize) {
				this.hasMore = false
			}
			this.feedbacks = feedbacks
		})
	}
}
