import { Badge } from 'components/ui/badge'
import { RestaurantSearch } from 'grpc/gen/admin/v1/admin'
import { formatRestaurantStatus, formatRestaurantType } from 'helpers/RestaurantHelper'
import { formatUrl } from 'helpers/UrlHelper'
import { observer } from 'mobx-react'
import React from 'react'
import { Link } from 'react-router-dom'

export interface RestaurantsListItemComponentProps {
	restaurant: RestaurantSearch
}

@observer
export default class RestaurantsListItemComponent extends React.Component<RestaurantsListItemComponentProps> {
	public render(): React.ReactNode {
		const { restaurant } = this.props
		return (
			<Link to={`/restaurant/${restaurant.id}`}>
				<div className='flex items-center space-x-4 rtl:space-x-reverse'>
					<div className='flex-shrink-0'>
						<img className='object-cover w-12 h-12 rounded-xl' src={formatUrl(restaurant.url)} />
					</div>
					<div className='flex-1 min-w-0'>
						<p className='text-xs truncate'>{formatRestaurantType(restaurant.type)}</p>
						<p className='font-medium truncate '>{restaurant.name}</p>
					</div>
					<div className='inline-flex items-center text-base'>
						<Badge>{formatRestaurantStatus(restaurant.status)}</Badge>
					</div>
				</div>
			</Link>
		)
	}
}
