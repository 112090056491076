import SessionUseCase from 'domain/usecase/session/SessionService'
import SessionRepository from 'domain/repository/session/SessionRepository'
import { RpcOptions, UnaryCall, NextUnaryFn, MethodInfo } from '@protobuf-ts/runtime-rpc'

export default function AuthorizationInterceptor() {
	return {
		interceptUnary(next: NextUnaryFn, method: MethodInfo, input: object, options: RpcOptions): UnaryCall {
			const useCase = new SessionUseCase(new SessionRepository())
			if (!options.meta) {
				options.meta = {}
			}
			const accessToken = useCase.getValidAccessTokenOrNull()
			if (accessToken != null) {
				options.meta['Authorization'] = accessToken.getRaw()
			}
			return next(method, input, options)
		},
	}
}
