import ErrorGRPC from 'domain/entity/grpc/models/ErrorGrpc'
import LoginUseCase from 'domain/usecase/login/LoginUseCase'
import { ErrorCode } from 'grpc/gen/overall/v1/overall'
import { makeObservable, observable } from 'mobx'
import FormValidator from 'presentation/util/FormValidator'

export default class VerificationEmailViewModel {
	@observable public emailQuery: string
	@observable public errorMessageEmail: string | null
	@observable public isLoading: boolean

	private loginUseCase: LoginUseCase

	public constructor(loginUseCase: LoginUseCase) {
		this.emailQuery = ''
		this.errorMessageEmail = null
		this.isLoading = false

		this.loginUseCase = loginUseCase
		makeObservable(this)
	}

	public onEmailQueryChanged(query: string) {
		this.emailQuery = query
	}

	public async onClickVerifyEmail(callback: () => void) {
		if (!this.validateEmailForm()) {
			return
		}

		try {
			await this.loginUseCase.verificationEmail(this.emailQuery)
			callback()
		} catch (error) {
			if (error instanceof ErrorGRPC) {
				console.log(error)
				switch ((error as ErrorGRPC).code) {
					case ErrorCode.TOO_MANY_REQUEST:
						this.errorMessageEmail = 'Слишком много запросов, попробуйте позднее'
						break
					default:
						break
				}
			}
		}
	}

	private validateEmailForm(): boolean {
		if (!this.emailQuery) {
			return false
		}
		if (!FormValidator.isValidEmail(this.emailQuery)) {
			this.errorMessageEmail = 'Email format is not valid'
			return false
		}
		return true
	}
}
