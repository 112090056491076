import ErrorGRPC from 'domain/entity/grpc/models/ErrorGrpc'
import LoginUseCase from 'domain/usecase/login/LoginUseCase'
import { ErrorCode } from 'grpc/gen/overall/v1/overall'
import { makeObservable, observable } from 'mobx'

export default class VerificationEmailCodeViewModel {
	@observable public codeQuery: string
	@observable public errorMessageCode: string | null
	@observable public isLoading: boolean

	private loginUseCase: LoginUseCase

	public constructor(loginUseCase: LoginUseCase) {
		this.codeQuery = ''
		this.errorMessageCode = null
		this.isLoading = false

		this.loginUseCase = loginUseCase
		makeObservable(this)
	}

	public onCodeQueryChanged(query: string) {
		this.codeQuery = query
	}

	public async onClickVerifyCode(callback: () => void): Promise<void> {
		if (!this.validateCodeForm()) {
			return
		}

		const verification = this.loginUseCase.getActiveVerificationMaybe()
		if (verification == null) {
			return
		}

		try {
			const verificationEmailCode = await this.loginUseCase.verificationEmailCode(verification, this.codeQuery)
			if (verificationEmailCode == null || verificationEmailCode.certificateId == null) {
				return
			}
			await this.loginUseCase.signIn(verificationEmailCode.certificateId)
			callback()
		} catch (error) {
			if (error instanceof ErrorGRPC) {
				console.log(error)
				switch ((error as ErrorGRPC).code) {
					case ErrorCode.TOO_MANY_REQUEST:
						this.errorMessageCode = 'Слишком много запросов, попробуйте позднее'
						break
					default:
						break
				}
			}
		}
	}

	private validateCodeForm(): boolean {
		if (!this.codeQuery) {
			return false
		}
		if (this.codeQuery.length > 4) {
			this.errorMessageCode = 'invalid code'
		}
		this.errorMessageCode = null
		return true
	}
}
