import AdminUseCase from 'domain/usecase/admin/AdminUseCase'
import { RestaurantSearch } from 'grpc/gen/foodhunt/core/v1/core'
import { makeObservable, observable, runInAction } from 'mobx'
import { NavigateFunction } from 'react-router-dom'

export default class RestaurantViewModel {
	@observable public restaurant: RestaurantSearch | null

	private useCase: AdminUseCase
	private navigate: NavigateFunction

	public constructor(useCase: AdminUseCase, navigate: NavigateFunction) {
		this.restaurant = null

		this.useCase = useCase
		this.navigate = navigate

		makeObservable(this)
	}

	public async fetchRestaurant(id: number) {
		const response = await this.useCase.getRestaurantById(id)
		runInAction(() => {
			this.restaurant = response
		})
	}

	public async onClickEditRestaurant() {
		this.navigate(`/restaurant/${this.restaurant?.id}/settings`)
		return null
	}

	public async onClickDiscardRestaurant() {
		this.navigate(-1)
		return null
	}
}
