import { RestaurantFoodFeedback } from 'grpc/gen/admin/v1/admin'
import moment from 'moment'
import ImageProfile from 'presentation/shared/ImageProfile'
import React from 'react'
import Moment from 'react-moment'
import { Link } from 'react-router-dom'

export interface HomeListItemProps {
	feedback: RestaurantFoodFeedback
}

export default function HomeListItemComponent({ feedback }: HomeListItemProps): JSX.Element {
	return (
		<article>
			<div className='flex items-center mb-4'>
				<ImageProfile url={feedback.profile?.url} />
				<div className='font-medium dark:text-white'>
					<div>
						<p>{feedback.name}</p>
						<div className='block text-sm text-gray-500 dark:text-gray-400'>@{feedback.profile?.nickName}</div>
					</div>
				</div>
			</div>
			<div className='flex items-center mb-1 space-x-1 rtl:space-x-reverse'>
				{Array.from(Array(feedback.rating).keys()).map((_, index) => (
					<StarStatus key={index} status={true} />
				))}

				{Array.from(Array(5 - feedback.rating).keys()).map((_, index) => (
					<StarStatus key={index} status={false} />
				))}
				<h3 className='ms-2 text-sm font-semibold text-gray-900 dark:text-white'>
					<Moment format='LL' date={moment.utc(1000 * Number(feedback.updated?.seconds))} />
				</h3>
			</div>
			<footer className='mb-5 text-sm text-gray-600 dark:text-gray-400'>
				<p>
					Пользователь зарегистрирован <Moment fromNow date={moment.utc(1000 * Number(feedback.updated?.seconds))} />
				</p>
			</footer>
			<p className='mb-2 text-gray-600 dark:text-gray-400'>{feedback.description}</p>
			<div className='mb-3 text-gray-600 dark:text-gray-400'>
				{feedback.urls.map((url, index) => (
					<div key={index}>
						<img className='object-cover h-72 w-72 max-w-lg rounded-lg' src={url} alt='image description' />
					</div>
				))}
			</div>
			<aside>
				<div className='flex items-center mt-6'>
					<a
						href='#'
						className='px-2 py-1.5 text-xs font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700'>
						Опубликовать
					</a>
					<Link
						to={`/feedback/${feedback.id}`}
						className='ps-4 text-sm font-medium text-blue-600 hover:underline dark:text-blue-500 border-gray-200 ms-4 border-s md:mb-0 dark:border-gray-600'>
						Подробрнее
					</Link>
				</div>
			</aside>
		</article>
	)
}

export interface StarStatusProps {
	status: boolean
}

function StarStatus({ status }: StarStatusProps) {
	return (
		<svg
			className={status ? 'w-4 h-4 text-yellow-300' : 'w-4 h-4 text-gray-300 dark:text-gray-500'}
			aria-hidden='true'
			xmlns='http://www.w3.org/2000/svg'
			fill='currentColor'
			viewBox='0 0 22 20'>
			<path d='M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z' />
		</svg>
	)
}
