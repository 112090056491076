import React, { useEffect } from 'react'
import NavBarView from 'presentation/view/navigation/NavigationBarView'
import HomeListComponent from './HomeListComponent'
import HomeListViewModel from 'presentation/viewmodel/home/HomeListViewModel'
import AdminRepository from 'domain/repository/admin/AdminRepository'
import AdminUseCase from 'domain/usecase/admin/AdminUseCase'

export default function HomeView() {
	const adminUseCase = new AdminUseCase(new AdminRepository())
	const homeListViewModel = new HomeListViewModel(adminUseCase)

	useEffect(() => {
		const dataFetch = async () => {
			if (homeListViewModel.feedbacks.length == 0 && !homeListViewModel.isLoading) {
				try {
					await homeListViewModel.fetchData()
				} catch (error) {
					console.log(error)
				}
			}
		}
		dataFetch()
	}, [])

	return (
		<div className='flex min-h-screen w-full flex-col'>
			<NavBarView />
			<div className='flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-8'>
				<HomeListComponent viewModel={homeListViewModel} />
			</div>
		</div>
	)
}
