// @generated by protobuf-ts 2.9.4
// @generated from protobuf file "entrum/lite/v1/lite.proto" (package "entrum.lite.v1", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { LiteApi } from "./lite";
import type { VerificationEmailConfirmResponse } from "./lite";
import type { VerificationEmailConfirmRequest } from "./lite";
import type { VerificationEmailResponse } from "./lite";
import type { VerificationEmailRequest } from "./lite";
import type { DeleteResponse } from "./lite";
import type { DeleteRequest } from "./lite";
import type { RefreshResponse } from "./lite";
import type { RefreshRequest } from "./lite";
import type { SignOutResponse } from "./lite";
import type { SignOutRequest } from "./lite";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { SignInResponse } from "./lite";
import type { SignInRequest } from "./lite";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service entrum.lite.v1.LiteApi
 */
export interface ILiteApiClient {
    /**
     * @generated from protobuf rpc: SignIn(entrum.lite.v1.SignInRequest) returns (entrum.lite.v1.SignInResponse);
     */
    signIn(input: SignInRequest, options?: RpcOptions): UnaryCall<SignInRequest, SignInResponse>;
    /**
     * @generated from protobuf rpc: SignOut(entrum.lite.v1.SignOutRequest) returns (entrum.lite.v1.SignOutResponse);
     */
    signOut(input: SignOutRequest, options?: RpcOptions): UnaryCall<SignOutRequest, SignOutResponse>;
    /**
     * @generated from protobuf rpc: Refresh(entrum.lite.v1.RefreshRequest) returns (entrum.lite.v1.RefreshResponse);
     */
    refresh(input: RefreshRequest, options?: RpcOptions): UnaryCall<RefreshRequest, RefreshResponse>;
    /**
     * @generated from protobuf rpc: Delete(entrum.lite.v1.DeleteRequest) returns (entrum.lite.v1.DeleteResponse);
     */
    delete(input: DeleteRequest, options?: RpcOptions): UnaryCall<DeleteRequest, DeleteResponse>;
    /**
     * @generated from protobuf rpc: VerificationEmail(entrum.lite.v1.VerificationEmailRequest) returns (entrum.lite.v1.VerificationEmailResponse);
     */
    verificationEmail(input: VerificationEmailRequest, options?: RpcOptions): UnaryCall<VerificationEmailRequest, VerificationEmailResponse>;
    /**
     * @generated from protobuf rpc: VerificationEmailConfirm(entrum.lite.v1.VerificationEmailConfirmRequest) returns (entrum.lite.v1.VerificationEmailConfirmResponse);
     */
    verificationEmailConfirm(input: VerificationEmailConfirmRequest, options?: RpcOptions): UnaryCall<VerificationEmailConfirmRequest, VerificationEmailConfirmResponse>;
}
/**
 * @generated from protobuf service entrum.lite.v1.LiteApi
 */
export class LiteApiClient implements ILiteApiClient, ServiceInfo {
    typeName = LiteApi.typeName;
    methods = LiteApi.methods;
    options = LiteApi.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: SignIn(entrum.lite.v1.SignInRequest) returns (entrum.lite.v1.SignInResponse);
     */
    signIn(input: SignInRequest, options?: RpcOptions): UnaryCall<SignInRequest, SignInResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<SignInRequest, SignInResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SignOut(entrum.lite.v1.SignOutRequest) returns (entrum.lite.v1.SignOutResponse);
     */
    signOut(input: SignOutRequest, options?: RpcOptions): UnaryCall<SignOutRequest, SignOutResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<SignOutRequest, SignOutResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Refresh(entrum.lite.v1.RefreshRequest) returns (entrum.lite.v1.RefreshResponse);
     */
    refresh(input: RefreshRequest, options?: RpcOptions): UnaryCall<RefreshRequest, RefreshResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<RefreshRequest, RefreshResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Delete(entrum.lite.v1.DeleteRequest) returns (entrum.lite.v1.DeleteResponse);
     */
    delete(input: DeleteRequest, options?: RpcOptions): UnaryCall<DeleteRequest, DeleteResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteRequest, DeleteResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: VerificationEmail(entrum.lite.v1.VerificationEmailRequest) returns (entrum.lite.v1.VerificationEmailResponse);
     */
    verificationEmail(input: VerificationEmailRequest, options?: RpcOptions): UnaryCall<VerificationEmailRequest, VerificationEmailResponse> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<VerificationEmailRequest, VerificationEmailResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: VerificationEmailConfirm(entrum.lite.v1.VerificationEmailConfirmRequest) returns (entrum.lite.v1.VerificationEmailConfirmResponse);
     */
    verificationEmailConfirm(input: VerificationEmailConfirmRequest, options?: RpcOptions): UnaryCall<VerificationEmailConfirmRequest, VerificationEmailConfirmResponse> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<VerificationEmailConfirmRequest, VerificationEmailConfirmResponse>("unary", this._transport, method, opt, input);
    }
}
