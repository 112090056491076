import AdminUseCase from 'domain/usecase/admin/AdminUseCase'
import { RestaurantFilters, RestaurantSearch, RestaurantStatus, RestaurantType } from 'grpc/gen/admin/v1/admin'
import { makeObservable, observable, runInAction } from 'mobx'

export default class RestaurantsViewModel {
	@observable public restaurants: RestaurantSearch[]
	@observable public restaurantName: string
	@observable public restaurantStatus: RestaurantStatus
	@observable public restaurantType: RestaurantType
	@observable public restaurantIds: number[]

	@observable public isLoading: boolean
	@observable public hasMore: boolean

	private page: number
	private pageSize: number

	private useCase: AdminUseCase
	public constructor(useCase: AdminUseCase) {
		this.restaurants = []

		this.restaurantName = ''
		this.restaurantStatus = RestaurantStatus.REST_STATUS_RELEASED
		this.restaurantType = RestaurantType.REST_TYPE_INVALID
		this.restaurantIds = []

		this.isLoading = false
		this.hasMore = true

		this.page = 1
		this.pageSize = 10

		this.useCase = useCase
		makeObservable(this)
	}

	public async fetchRestaurants() {
		const filter = RestaurantFilters.fromJson({
			status: this.restaurantStatus,
			type: this.restaurantType,
			restaurantIds: this.restaurantIds,
			name: this.restaurantName,
		})
		const restaurants = await this.useCase.getRestaurants(filter, this.page, this.pageSize)
		runInAction(() => {
			if (restaurants.length < this.pageSize) {
				this.hasMore = false
			}
			this.restaurants = restaurants
		})
	}

	public async onRestaurantNameChanged(name: string) {
		this.restaurantName = name
		await this.fetchRestaurants()
	}
}
