import React, { useEffect } from 'react'
import NavBarView from 'presentation/view/navigation/NavigationBarView'
import RestaurantsViewModel from 'presentation/viewmodel/restaurants/RestaurantsViewModel'
import RestaurantsListComponent from './RestaurantsListComponent'
import AdminUseCase from 'domain/usecase/admin/AdminUseCase'
import AdminRepository from 'domain/repository/admin/AdminRepository'

export default function RestaurantsView() {
	const adminUseCase = new AdminUseCase(new AdminRepository())
	const viewModel = new RestaurantsViewModel(adminUseCase)

	useEffect(() => {
		const dataFetch = async () => {
			await viewModel.fetchRestaurants()
		}
		dataFetch()
	}, [])

	return (
		<div>
			<NavBarView />
			<RestaurantsListComponent viewModel={viewModel} />
		</div>
	)
}
