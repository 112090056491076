import SessionUseCase from 'domain/usecase/session/SessionService'
import { makeObservable } from 'mobx'
import { NavigateFunction } from 'react-router-dom'

export default class NavigationBarViewModel {
	private useCase: SessionUseCase
	private navigate: NavigateFunction
	public constructor(useCase: SessionUseCase, navigate: NavigateFunction) {
		this.useCase = useCase
		this.navigate = navigate
		makeObservable(this)
	}

	public logout() {
		this.useCase.destroyToken()
		this.navigate('/verification')
	}
}
