import React from 'react'
import { Menu, Package2, CircleUser } from 'lucide-react'
import { Sheet } from 'components/ui/sheet'
import { Button } from 'components/ui/button'
import { SheetContent, SheetTrigger } from 'components/ui/sheet'
import { Link, useNavigate } from 'react-router-dom'
import { ModeToggle } from 'presentation/shared/ModeToggle'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from 'components/ui/dropdown-menu'
import NavigationBarViewModel from 'presentation/viewmodel/navigation/NavigationBarViewModel'
import SessionUseCase from 'domain/usecase/session/SessionService'
import SessionRepository from 'domain/repository/session/SessionRepository'

function NavBarView() {
	const navigate = useNavigate()
	const useCase = new SessionUseCase(new SessionRepository())
	const viewModel = new NavigationBarViewModel(useCase, navigate)
	return (
		<div className='border-b'>
			<header className='container sticky top-0 flex h-16 items-center gap-4 px-4 md:px-6'>
				<nav className='hidden flex-col gap-6 text-lg font-medium md:flex md:flex-row md:items-center md:gap-5 md:text-sm lg:gap-6'>
					<Link to={`/`} className='flex items-center gap-2 text-lg font-semibold md:text-base'>
						<Package2 className='h-6 w-6' />
						<span className='sr-only'>FoodTwoSteps</span>
					</Link>
					<Link to={`/`} className='text-muted-foreground transition-colors hover:text-foreground'>
						Главная
					</Link>
					<Link to={`/restaurants`} className='text-muted-foreground transition-colors hover:text-foreground'>
						Рестораны
					</Link>
				</nav>
				<Sheet>
					<SheetTrigger asChild>
						<Button variant='outline' size='icon' className='shrink-0 md:hidden'>
							<Menu className='h-5 w-5' />
							<span className='sr-only'>Toggle navigation menu</span>
						</Button>
					</SheetTrigger>
					<SheetContent side='left'>
						<nav className='grid gap-6 text-lg font-medium'>
							<Link to={`/`} className='flex items-center gap-2 text-lg font-semibold'>
								<Package2 className='h-6 w-6' />
								<span className='sr-only'>Acme Inc</span>
							</Link>
							<Link to={`/`} className='hover:text-foreground'>
								Главная
							</Link>
							<Link to={`/restaurants`} className='hover:text-foreground'>
								Рестораны
							</Link>
						</nav>
					</SheetContent>
				</Sheet>
				<div className='flex w-full items-center gap-4 md:ml-auto md:gap-2 lg:gap-4'>
					<div className='ml-auto flex-1 sm:flex-initial'></div>
					<ModeToggle />
					<DropdownMenu>
						<DropdownMenuTrigger asChild>
							<Button variant='secondary' size='icon' className='rounded-full'>
								<CircleUser className='h-5 w-5' />
								<span className='sr-only'>Toggle user menu</span>
							</Button>
						</DropdownMenuTrigger>
						<DropdownMenuContent onClick={() => viewModel.logout()} align='end'>
							<DropdownMenuItem>Выйти</DropdownMenuItem>
						</DropdownMenuContent>
					</DropdownMenu>
				</div>
			</header>
		</div>
	)
}

export default NavBarView
