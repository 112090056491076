import React from 'react'
import NavBarView from 'presentation/view/navigation/NavigationBarView'
import RestaurantViewModel from 'presentation/viewmodel/restaurant/RestaurantViewModel'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import AdminUseCase from 'domain/usecase/admin/AdminUseCase'
import AdminRepository from 'domain/repository/admin/AdminRepository'
import RestaurantComponent from './RestaurantComponent'

export default function RestaurantView() {
	const { restaurantId } = useParams()
	const navigate = useNavigate()

	const useCase = new AdminUseCase(new AdminRepository())
	const viewModel = new RestaurantViewModel(useCase, navigate)

	useEffect(() => {
		const dataFetch = async () => {
			await viewModel.fetchRestaurant(Number(restaurantId))
		}
		dataFetch()
	}, [])

	return (
		<div>
			<NavBarView />
			<RestaurantComponent viewModel={viewModel} />
		</div>
	)
}
