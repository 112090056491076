import SessionRepository from 'domain/repository/session/SessionRepository'
import AuthRepository from 'domain/repository/auth/AuthRepository'
import VerificationRepository from 'domain/repository/verification/VerificationRepository'
import SessionUseCase from 'domain/usecase/session/SessionService'
import LoginUseCase from 'domain/usecase/login/LoginUseCase'

export default async function VerifyAndRefreshTokenInterceptor() {
	const sessionRepository = new SessionRepository()
	const sessionUseCase = new SessionUseCase(sessionRepository)
	const loginUseCase = new LoginUseCase(new AuthRepository(), sessionRepository, new VerificationRepository())

	const accessToken = sessionUseCase.getValidAccessTokenOrNull()
	if (accessToken == null) {
		const tokenPair = await loginUseCase.refreshToken()
		if (tokenPair == null) {
			sessionUseCase.destroyToken()
		}
	}
}
