import Verification from 'domain/entity/verification/models/Verification'

export default class VerificationRepository {
	private readonly VERIFICATION = 'verification'

	private localStorage: Storage

	constructor() {
		this.localStorage = window.localStorage
	}

	getVerification(): Verification | null {
		try {
			return Object.assign(new Verification(), JSON.parse(this.localStorage.getItem(this.VERIFICATION) ?? ''))
		} catch {
			return null
		}
	}

	saveVerification(verification: Verification): void {
		this.localStorage.setItem(this.VERIFICATION, JSON.stringify(verification))
	}

	destroyVerification(): void {
		this.localStorage.removeItem(this.VERIFICATION)
	}
}
