import {
	RestaurantFilters,
	RestaurantFood,
	RestaurantFoodFeedback,
	RestaurantFoodFeedbackStatus,
	RestaurantPoint,
	RestaurantSearch,
} from 'grpc/gen/admin/v1/admin'
import AdminRepository from 'domain/repository/admin/AdminRepository'

export default class AdminUseCase {
	private repository: AdminRepository

	constructor(repository: AdminRepository) {
		this.repository = repository
	}

	public async getRestaurantById(id: number): Promise<RestaurantSearch | null> {
		return await this.repository.getRestaurantById(id)
	}

	public async getRestaurants(filters: RestaurantFilters, page: number, pageSize: number): Promise<RestaurantSearch[]> {
		return await this.repository.getRestaurants(filters, page, pageSize)
	}

	public async getRestaurantFoodFeedback(
		status: RestaurantFoodFeedbackStatus,
		feedbackIds: number[] | null,
		page: number,
		pageSize: number,
	): Promise<RestaurantFoodFeedback[]> {
		return await this.repository.getRestaurantFoodFeedback(status, feedbackIds, page, pageSize)
	}

	public async getRestaurantPointById(pointId: number): Promise<RestaurantPoint | null> {
		return await this.repository.getRestaurantPointById(pointId)
	}

	public async getRestaurantFoodById(foodId: number): Promise<RestaurantFood | null> {
		return await this.repository.getRestaurantFoodById(foodId)
	}

	public async getRestaurantFoodFeedbackById(feedbackId: number): Promise<RestaurantFoodFeedback | null> {
		return await this.repository.getRestaurantFoodFeedbackById(feedbackId)
	}

	public async updateRestaurant(id: number, name: string, fileId: number): Promise<number> {
		return await this.repository.updateRestaurant(id, name, fileId, 0, 0)
	}
}
