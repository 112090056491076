import React from 'react'
import VerificationEmailViewModel from 'presentation/viewmodel/verification-email/VerificationEmailViewModel'
import { observer } from 'mobx-react'
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from 'components/ui/card'
import { Label } from 'components/ui/label'
import { Input } from 'components/ui/input'
import { Button } from 'components/ui/button'

export interface VerificationEmailComponentProps {
	viewModel: VerificationEmailViewModel
	onVerificationEmail: () => void
}

@observer
export default class VerificationEmailComponent extends React.Component<VerificationEmailComponentProps> {
	public render(): React.ReactNode {
		const { emailQuery, isLoading } = this.props.viewModel
		return (
			<Card className='w-full max-w-sm'>
				<CardHeader>
					<CardTitle className='text-2xl'>Войти в Админ Панель</CardTitle>
					<CardDescription>Введите Ваш email чтобы войти в админ панель.</CardDescription>
				</CardHeader>
				<CardContent className='grid gap-4'>
					<div className='grid gap-2'>
						<Label htmlFor='email'>Email</Label>
						<Input
							id='email'
							type='email'
							placeholder='name@example.com'
							onChange={(e: React.FormEvent<HTMLInputElement>): void => {
								this.props.viewModel.onEmailQueryChanged(e.currentTarget.value)
							}}
							value={emailQuery}
							disabled={isLoading}
							required
						/>
					</div>
				</CardContent>
				<CardFooter>
					<Button
						className='w-full'
						onClick={async () => await this.props.viewModel.onClickVerifyEmail(this.props.onVerificationEmail)}>
						Продолжить
					</Button>
				</CardFooter>
			</Card>
		)
	}
}
