import AccessToken from 'domain/entity/session/models/AccessToken'
import SessionRepository from 'domain/repository/session/SessionRepository'

export default class SessionUseCase {
	private repository: SessionRepository

	constructor(repository: SessionRepository) {
		this.repository = repository
	}

	public getAccessToken(): AccessToken | null {
		return this.repository.getAccessToken()
	}

	public getValidAccessTokenOrNull(): AccessToken | null {
		const accessToken = this.repository.getAccessToken()
		if (accessToken == null) {
			return null
		}
		if (!accessToken.isValidAdminToken()) {
			return null
		}
		return accessToken
	}

	public destroyToken(): void {
		this.repository.destroyToken()
	}
}
