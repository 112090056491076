import AdminUseCase from 'domain/usecase/admin/AdminUseCase'
import { RestaurantFood, RestaurantFoodFeedback, RestaurantPoint } from 'grpc/gen/admin/v1/admin'
import { makeObservable, observable } from 'mobx'

export default class FeedbackViewModel {
	@observable public feedback: RestaurantFoodFeedback | null
	@observable public isFeedbackLoading: boolean

	@observable public point: RestaurantPoint | null
	@observable public isPointLoading: boolean

	@observable public food: RestaurantFood | null
	@observable public isFoodLoading: boolean

	private useCase: AdminUseCase

	public constructor(useCase: AdminUseCase) {
		this.feedback = null
		this.isFeedbackLoading = false

		this.point = null
		this.isPointLoading = false

		this.food = null
		this.isFoodLoading = false

		this.useCase = useCase
		makeObservable(this)
	}

	public async fetchRestaurantPoint(pointId: number): Promise<RestaurantPoint | null> {
		this.point = await this.useCase.getRestaurantPointById(pointId)
		return this.point
	}
	public async fetchRestaurantFood(foodId: number): Promise<RestaurantFood | null> {
		this.food = await this.useCase.getRestaurantFoodById(foodId)
		return this.food
	}
	public async fetchFeedback(feedbackId: number): Promise<RestaurantFoodFeedback | null> {
		this.feedback = await this.useCase.getRestaurantFoodFeedbackById(feedbackId)
		return this.feedback
	}
}
