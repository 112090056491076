export default class UploadFile {
	private fileId: number
	private url: string

	public constructor(fileId: number, url: string) {
		this.fileId = fileId
		this.url = url
	}

	public getFileId(): number {
		return this.fileId
	}

	public getUrl(): string {
		return this.url
	}
}
