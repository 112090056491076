import React, { useEffect } from 'react'
import NavBarView from 'presentation/view/navigation/NavigationBarView'
import RestaurantSettingsViewModel from 'presentation/viewmodel/restaurant-settings/RestaurantSettingsViewModel'
import { useNavigate, useParams } from 'react-router-dom'
import AdminUseCase from 'domain/usecase/admin/AdminUseCase'
import AdminRepository from 'domain/repository/admin/AdminRepository'
import RestaurantSettingsComponent from './RestaurantSettingsComponent'
import StorageRepository from 'domain/repository/storage/StorageRepository'
import StorageUseCase from 'domain/usecase/storage/StorageUseCase'
import SessionRepository from 'domain/repository/session/SessionRepository'

export default function RestaurantSettingsView() {
	const { restaurantId } = useParams()
	const navigate = useNavigate()

	const storageUseCase = new StorageUseCase(new StorageRepository(), new SessionRepository())
	const useCase = new AdminUseCase(new AdminRepository())
	const viewModel = new RestaurantSettingsViewModel(useCase, storageUseCase, navigate)

	useEffect(() => {
		const dataFetch = async () => {
			await viewModel.fetchRestaurant(Number(restaurantId))
		}
		dataFetch()
	}, [])

	return (
		<div>
			<NavBarView />
			<RestaurantSettingsComponent viewModel={viewModel} />
		</div>
	)
}
