import { ErrorCode } from 'grpc/gen/overall/v1/overall'

export default class ErrorGRPC extends Error {
	code: ErrorCode
	message: string
	constructor(code: ErrorCode, message: string) {
		super(message)
		this.code = code
		this.message = message
	}
}
