import React from 'react'
import VerificationEmailCodeViewModel from 'presentation/viewmodel/verification-email-code/VerificationEmailCodeViewModel'
import { observer } from 'mobx-react'
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from 'components/ui/card'
import { Label } from 'components/ui/label'
import { Input } from 'components/ui/input'
import { Button } from 'components/ui/button'

export interface VerificationEmailCodeComponentProps {
	viewModel: VerificationEmailCodeViewModel
	onVerificationEmailCompleted: () => void
}

@observer
export default class VerificationEmailCodeComponent extends React.Component<VerificationEmailCodeComponentProps> {
	public render(): React.ReactNode {
		const { codeQuery, isLoading } = this.props.viewModel

		return (
			<Card className='w-full max-w-sm'>
				<CardHeader>
					<CardTitle className='text-2xl'>Введите код подтверждения</CardTitle>
					<CardDescription>Код подтверждения был отправлен на почтовый ящик.</CardDescription>
				</CardHeader>
				<CardContent className='grid gap-4'>
					<div className='grid gap-2'>
						<Label htmlFor='code'>Код подтверждения</Label>
						<Input
							id='code'
							type='number'
							placeholder='****'
							onChange={(e: React.FormEvent<HTMLInputElement>): void => {
								this.props.viewModel.onCodeQueryChanged(e.currentTarget.value)
							}}
							value={codeQuery}
							disabled={isLoading}
							required
						/>
					</div>
				</CardContent>
				<CardFooter>
					<Button
						className='w-full'
						onClick={async () => await this.props.viewModel.onClickVerifyCode(this.props.onVerificationEmailCompleted)}>
						Продолжить
					</Button>
				</CardFooter>
			</Card>
		)
	}
}
