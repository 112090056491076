export default class RefreshToken {
	private refreshToken: string

	public constructor(refreshToken: string) {
		this.refreshToken = refreshToken
	}

	public getRaw(): string {
		return this.refreshToken
	}
}
