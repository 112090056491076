import React from 'react'
import { observer } from 'mobx-react'
import { Button } from 'components/ui/button'
import { ChevronLeft } from 'lucide-react'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card'
import { Label } from 'components/ui/label'
import { Input } from 'components/ui/input'
import { Badge } from 'components/ui/badge'
import { formatUrl } from 'helpers/UrlHelper'
import { getImageData } from 'helpers/InputHelper'
import RestaurantSettingsViewModel from 'presentation/viewmodel/restaurant-settings/RestaurantSettingsViewModel'
import { formatRestaurantStatus } from 'helpers/RestaurantHelper'

export interface RestaurantSettingsComponentProps {
	viewModel: RestaurantSettingsViewModel
}

@observer
export default class RestaurantSettingsComponent extends React.Component<RestaurantSettingsComponentProps> {
	public render(): React.ReactNode {
		const { restaurant, restaurantName, restaurantUrl } = this.props.viewModel
		return (
			<main className='grid flex-1 items-start gap-4 p-4 sm:px-6 md:py-6 md:gap-8'>
				<div className='mx-auto grid max-w-[59rem] flex-1 auto-rows-max gap-4'>
					<div className='flex items-center gap-4'>
						<Button
							onClick={() => this.props.viewModel.onClickDiscardRestaurant()}
							variant='outline'
							size='icon'
							className='h-7 w-7'>
							<ChevronLeft className='h-4 w-4' />
							<span className='sr-only'>Back</span>
						</Button>
						<h1 className='flex-1 shrink-0 whitespace-nowrap text-xl font-semibold tracking-tight sm:grow-0'>
							{restaurantName}
						</h1>
						<Badge variant='outline' className='ml-auto sm:ml-0'>
							{formatRestaurantStatus(restaurant?.status ?? 0)}
						</Badge>
						<div className='hidden items-center gap-2 md:ml-auto md:flex'>
							<Button onClick={() => this.props.viewModel.onClickDiscardRestaurant()} variant='outline' size='sm'>
								Отменить
							</Button>
							<Button onClick={async () => await this.props.viewModel.onClickSaveRestaurant()} size='sm'>
								Сохранить
							</Button>
						</div>
					</div>
					<div className='grid gap-4 md:grid-cols-[1fr_250px] lg:grid-cols-3 lg:gap-8'>
						<div className='grid auto-rows-max items-start gap-4 lg:col-span-2 lg:gap-8'>
							<Card x-chunk='dashboard-07-chunk-0'>
								<CardHeader>
									<CardTitle>Детали ресторана</CardTitle>
									<CardDescription>Основные детали ресторана</CardDescription>
								</CardHeader>
								<CardContent>
									<div className='grid gap-6'>
										<div className='grid gap-3'>
											<Label htmlFor='name'>Название ресторана</Label>
											<Input
												id='name'
												type='text'
												className='w-full'
												onChange={(e: React.FormEvent<HTMLInputElement>): void => {
													this.props.viewModel.onRestaurantNameChanged(e.currentTarget.value)
												}}
												value={restaurantName}
											/>
										</div>
									</div>
								</CardContent>
							</Card>
							<Card className='overflow-hidden' x-chunk='dashboard-07-chunk-4'>
								<CardHeader>
									<CardTitle>Картинки ресторана</CardTitle>
									<CardDescription>Список главных картинок ресторана</CardDescription>
								</CardHeader>
								<CardContent>
									<div className='grid gap-2'>
										<img
											alt='Product image'
											className='object-cover w-full rounded-md'
											height='300'
											src={formatUrl(restaurantUrl)}
											width='300'
										/>
										<Input
											type='file'
											onChange={async event => {
												const { files, displayUrl } = getImageData(event)
												await this.props.viewModel.uploadFile(files)
											}}
										/>
									</div>
								</CardContent>
							</Card>
						</div>
						<div className='grid auto-rows-max items-start gap-4 lg:gap-8'>
							<Card x-chunk='dashboard-07-chunk-5'>
								<CardHeader>
									<CardTitle>Архивирование</CardTitle>
									<CardDescription>Структура архивации активного ресторана</CardDescription>
								</CardHeader>
								<CardContent>
									<div></div>
									<Button size='sm' variant='secondary'>
										Переместить в архив
									</Button>
								</CardContent>
							</Card>
						</div>
					</div>
					<div className='flex items-center justify-center gap-2 md:hidden'>
						<Button onClick={() => this.props.viewModel.onClickDiscardRestaurant()} variant='outline' size='sm'>
							Отменить
						</Button>
						<Button onClick={async () => await this.props.viewModel.onClickSaveRestaurant()} size='sm'>
							Сохранить
						</Button>
					</div>
				</div>
			</main>
		)
	}
}
