import React from 'react'
import { observer } from 'mobx-react'
import FeedbackViewModel from 'presentation/viewmodel/feedback/FeedbackViewModel'

export interface FeedbackComponentProps {
	viewModel: FeedbackViewModel
}

@observer
export default class FeedbackComponent extends React.Component<FeedbackComponentProps> {
	public render(): React.ReactNode {
		const { feedback } = this.props.viewModel
		return <div className='p-6 space-y-4 md:space-y-6 sm:p-8'>{feedback?.name}</div>
	}
}
