import { GrpcWebFetchTransport } from '@protobuf-ts/grpcweb-transport'
import { ServiceAuthUrl } from 'const'
import Verification from 'domain/entity/verification/models/Verification'
import {
	VerificationEmailRequest,
	VerificationEmailResponse,
	VerificationEmailConfirmRequest,
	VerificationEmailConfirmResponse,
	SignInRequest,
	SignInResponse,
	TokenPair,
	RefreshRequest,
	RefreshResponse,
} from 'grpc/gen/entrum/lite/v1/lite'
import { LiteApiClient } from 'grpc/gen/entrum/lite/v1/lite.client'
import { getResponseFromCall } from 'grpc/utils/response'

export default class AuthRepository {
	private client: LiteApiClient
	private meta: object

	constructor() {
		this.client = new LiteApiClient(
			new GrpcWebFetchTransport({
				baseUrl: ServiceAuthUrl,
			}),
		)
		this.meta = {}
	}

	public async verificationEmail(email: string): Promise<Verification | null> {
		const request = VerificationEmailRequest.fromJson({ email: email })
		const response = (await getResponseFromCall(this.client.verificationEmail(request))) as VerificationEmailResponse
		return new Verification()
			.setEmail(email)
			.setTransaction(response['transactionId'], response['transactionKey'], response['expiresAt']?.seconds ?? null)
	}

	public async verificationEmailCode(verification: Verification, code: string): Promise<Verification | null> {
		const request = VerificationEmailConfirmRequest.fromJson({
			transactionId: verification.transactionId,
			transactionKey: verification.transactionKey,
			code: code,
		})
		const response = (await getResponseFromCall(
			this.client.verificationEmailConfirm(request),
		)) as VerificationEmailConfirmResponse
		return verification
			.setCertificate(response['certificateId'], response['expiresAt']?.seconds ?? null)
			.cleanTransaction()
	}

	public async signIn(certificateId: string): Promise<TokenPair | null> {
		const request = SignInRequest.fromJson({ certificateId: certificateId })
		const response = (await getResponseFromCall(this.client.signIn(request))) as SignInResponse
		return response.data ?? null
	}

	public async refreshToken(refreshToken: string): Promise<TokenPair | null> {
		const request = RefreshRequest.fromJson({ refreshToken: refreshToken })
		const response = (await getResponseFromCall(this.client.refresh(request))) as RefreshResponse
		return response.data ?? null
	}
}
