export default class Verification {
	public email: string | null

	public transactionId: string | null
	public transactionKey: string | null
	private expiresTransactionAt: bigint | null

	public certificateId: string | null
	private expiresCertificateAt: bigint | null

	public constructor() {
		this.email = null
		this.transactionId = null
		this.transactionKey = null
		this.expiresTransactionAt = null
		this.certificateId = null
		this.expiresCertificateAt = null
	}

	public setEmail(email: string): Verification {
		this.email = email
		return this
	}

	public setTransaction(
		transactionId: string,
		transactionKey: string,
		expiresTransactionAt: bigint | null,
	): Verification {
		this.transactionId = transactionId
		this.transactionKey = transactionKey
		this.expiresTransactionAt = expiresTransactionAt
		this.cleanCertificate()
		return this
	}

	public cleanTransaction(): Verification {
		this.transactionId = null
		this.transactionKey = null
		this.expiresTransactionAt = null
		return this
	}

	public isTransactionActive(): boolean {
		if (this.transactionId == null || this.transactionKey == null || this.expiresTransactionAt == null) {
			return false
		}

		// проверяем не протух ли токен
		if (Date.now() / 1000 >= this.expiresTransactionAt) {
			return false
		}

		return true
	}

	public setCertificate(certificateId: string, expiresCertificateAt: bigint | null): Verification {
		this.certificateId = certificateId
		this.expiresCertificateAt = expiresCertificateAt
		this.cleanTransaction()
		return this
	}

	private cleanCertificate(): Verification {
		this.certificateId = null
		this.expiresCertificateAt = null
		return this
	}

	public isCertificationActive(): boolean {
		if (this.certificateId == null || this.expiresCertificateAt == null) {
			return false
		}

		// проверяем не протух ли токен
		if (Date.now() / 1000 >= this.expiresCertificateAt) {
			return false
		}

		return true
	}
}
