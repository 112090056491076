import React, { ReactNode, useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import SessionUseCase from 'domain/usecase/session/SessionService'
import SessionRepository from 'domain/repository/session/SessionRepository'
import VerifyAndRefreshTokenInterceptor from 'grpc/interceptors/refreshTokenInterceptor'
import LoadingView from 'presentation/shared/LoadingView'

interface RouterGuardProps {
	children: ReactNode
}

export default function RouterGuard({ children }: RouterGuardProps): JSX.Element {
	const sessionUseCase = new SessionUseCase(new SessionRepository())
	const [hasPermission, setHasPermission] = useState(false)
	const accessToken = sessionUseCase.getAccessToken()

	useEffect(() => {
		const dataFetch = async () => {
			await VerifyAndRefreshTokenInterceptor()
			setHasPermission(sessionUseCase.getValidAccessTokenOrNull() != null)
		}
		dataFetch()
	}, [])

	if (accessToken == null) {
		return <Navigate to='/verification' replace />
	}

	return hasPermission ? <>{children}</> : <LoadingView />
}
