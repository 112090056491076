// @generated by protobuf-ts 2.9.4
// @generated from protobuf file "entrum/lite/v1/lite.proto" (package "entrum.lite.v1", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../../../google/protobuf/timestamp";
/**
 * @generated from protobuf message entrum.lite.v1.SignInRequest
 */
export interface SignInRequest {
    /**
     * @generated from protobuf field: string certificate_id = 1;
     */
    certificateId: string;
}
/**
 * @generated from protobuf message entrum.lite.v1.SignInResponse
 */
export interface SignInResponse {
    /**
     * @generated from protobuf field: entrum.lite.v1.TokenPair data = 1;
     */
    data?: TokenPair;
}
/**
 * @generated from protobuf message entrum.lite.v1.TokenPair
 */
export interface TokenPair {
    /**
     * @generated from protobuf field: string id_token = 1;
     */
    idToken: string;
    /**
     * @generated from protobuf field: string refresh_token = 2;
     */
    refreshToken: string;
}
/**
 * @generated from protobuf message entrum.lite.v1.SignOutRequest
 */
export interface SignOutRequest {
    /**
     * @generated from protobuf field: string id_token = 1;
     */
    idToken: string;
}
/**
 * @generated from protobuf message entrum.lite.v1.SignOutResponse
 */
export interface SignOutResponse {
}
/**
 * @generated from protobuf message entrum.lite.v1.RefreshRequest
 */
export interface RefreshRequest {
    /**
     * @generated from protobuf field: string refresh_token = 1;
     */
    refreshToken: string;
}
/**
 * @generated from protobuf message entrum.lite.v1.RefreshResponse
 */
export interface RefreshResponse {
    /**
     * @generated from protobuf field: entrum.lite.v1.TokenPair data = 1;
     */
    data?: TokenPair;
}
/**
 * @generated from protobuf message entrum.lite.v1.DeleteRequest
 */
export interface DeleteRequest {
    /**
     * @generated from protobuf field: string id_token = 1;
     */
    idToken: string;
}
/**
 * @generated from protobuf message entrum.lite.v1.DeleteResponse
 */
export interface DeleteResponse {
}
/**
 * @generated from protobuf message entrum.lite.v1.VerificationEmailRequest
 */
export interface VerificationEmailRequest {
    /**
     * @generated from protobuf field: string email = 1;
     */
    email: string;
}
/**
 * @generated from protobuf message entrum.lite.v1.VerificationEmailResponse
 */
export interface VerificationEmailResponse {
    /**
     * @generated from protobuf field: string transaction_id = 1;
     */
    transactionId: string;
    /**
     * @generated from protobuf field: string transaction_key = 2;
     */
    transactionKey: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp expires_at = 3;
     */
    expiresAt?: Timestamp;
}
/**
 * @generated from protobuf message entrum.lite.v1.VerificationEmailConfirmRequest
 */
export interface VerificationEmailConfirmRequest {
    /**
     * @generated from protobuf field: string transaction_id = 1;
     */
    transactionId: string;
    /**
     * @generated from protobuf field: string transaction_key = 2;
     */
    transactionKey: string;
    /**
     * @generated from protobuf field: string code = 3;
     */
    code: string;
}
/**
 * @generated from protobuf message entrum.lite.v1.VerificationEmailConfirmResponse
 */
export interface VerificationEmailConfirmResponse {
    /**
     * @generated from protobuf field: string certificate_id = 1;
     */
    certificateId: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp expires_at = 2;
     */
    expiresAt?: Timestamp;
}
// @generated message type with reflection information, may provide speed optimized methods
class SignInRequest$Type extends MessageType<SignInRequest> {
    constructor() {
        super("entrum.lite.v1.SignInRequest", [
            { no: 1, name: "certificate_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SignInRequest>): SignInRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.certificateId = "";
        if (value !== undefined)
            reflectionMergePartial<SignInRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SignInRequest): SignInRequest {
        const message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            const [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string certificate_id */ 1:
                    message.certificateId = reader.string();
                    break;
                default:
                    const u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    const d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SignInRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string certificate_id = 1; */
        if (message.certificateId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.certificateId);
        const u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message entrum.lite.v1.SignInRequest
 */
export const SignInRequest = new SignInRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SignInResponse$Type extends MessageType<SignInResponse> {
    constructor() {
        super("entrum.lite.v1.SignInResponse", [
            { no: 1, name: "data", kind: "message", T: () => TokenPair }
        ]);
    }
    create(value?: PartialMessage<SignInResponse>): SignInResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<SignInResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SignInResponse): SignInResponse {
        const message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            const [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* entrum.lite.v1.TokenPair data */ 1:
                    message.data = TokenPair.internalBinaryRead(reader, reader.uint32(), options, message.data);
                    break;
                default:
                    const u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    const d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SignInResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* entrum.lite.v1.TokenPair data = 1; */
        if (message.data)
            TokenPair.internalBinaryWrite(message.data, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        const u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message entrum.lite.v1.SignInResponse
 */
export const SignInResponse = new SignInResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TokenPair$Type extends MessageType<TokenPair> {
    constructor() {
        super("entrum.lite.v1.TokenPair", [
            { no: 1, name: "id_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "refresh_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TokenPair>): TokenPair {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.idToken = "";
        message.refreshToken = "";
        if (value !== undefined)
            reflectionMergePartial<TokenPair>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TokenPair): TokenPair {
        const message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            const [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id_token */ 1:
                    message.idToken = reader.string();
                    break;
                case /* string refresh_token */ 2:
                    message.refreshToken = reader.string();
                    break;
                default:
                    const u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    const d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TokenPair, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id_token = 1; */
        if (message.idToken !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.idToken);
        /* string refresh_token = 2; */
        if (message.refreshToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.refreshToken);
        const u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message entrum.lite.v1.TokenPair
 */
export const TokenPair = new TokenPair$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SignOutRequest$Type extends MessageType<SignOutRequest> {
    constructor() {
        super("entrum.lite.v1.SignOutRequest", [
            { no: 1, name: "id_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SignOutRequest>): SignOutRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.idToken = "";
        if (value !== undefined)
            reflectionMergePartial<SignOutRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SignOutRequest): SignOutRequest {
        const message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            const [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id_token */ 1:
                    message.idToken = reader.string();
                    break;
                default:
                    const u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    const d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SignOutRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id_token = 1; */
        if (message.idToken !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.idToken);
        const u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message entrum.lite.v1.SignOutRequest
 */
export const SignOutRequest = new SignOutRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SignOutResponse$Type extends MessageType<SignOutResponse> {
    constructor() {
        super("entrum.lite.v1.SignOutResponse", []);
    }
    create(value?: PartialMessage<SignOutResponse>): SignOutResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<SignOutResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SignOutResponse): SignOutResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SignOutResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        const u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message entrum.lite.v1.SignOutResponse
 */
export const SignOutResponse = new SignOutResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RefreshRequest$Type extends MessageType<RefreshRequest> {
    constructor() {
        super("entrum.lite.v1.RefreshRequest", [
            { no: 1, name: "refresh_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RefreshRequest>): RefreshRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.refreshToken = "";
        if (value !== undefined)
            reflectionMergePartial<RefreshRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RefreshRequest): RefreshRequest {
        const message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            const [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string refresh_token */ 1:
                    message.refreshToken = reader.string();
                    break;
                default:
                    const u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    const d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RefreshRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string refresh_token = 1; */
        if (message.refreshToken !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.refreshToken);
        const u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message entrum.lite.v1.RefreshRequest
 */
export const RefreshRequest = new RefreshRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RefreshResponse$Type extends MessageType<RefreshResponse> {
    constructor() {
        super("entrum.lite.v1.RefreshResponse", [
            { no: 1, name: "data", kind: "message", T: () => TokenPair }
        ]);
    }
    create(value?: PartialMessage<RefreshResponse>): RefreshResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<RefreshResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RefreshResponse): RefreshResponse {
        const message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            const [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* entrum.lite.v1.TokenPair data */ 1:
                    message.data = TokenPair.internalBinaryRead(reader, reader.uint32(), options, message.data);
                    break;
                default:
                    const u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    const d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RefreshResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* entrum.lite.v1.TokenPair data = 1; */
        if (message.data)
            TokenPair.internalBinaryWrite(message.data, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        const u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message entrum.lite.v1.RefreshResponse
 */
export const RefreshResponse = new RefreshResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteRequest$Type extends MessageType<DeleteRequest> {
    constructor() {
        super("entrum.lite.v1.DeleteRequest", [
            { no: 1, name: "id_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteRequest>): DeleteRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.idToken = "";
        if (value !== undefined)
            reflectionMergePartial<DeleteRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteRequest): DeleteRequest {
        const message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            const [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id_token */ 1:
                    message.idToken = reader.string();
                    break;
                default:
                    const u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    const d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id_token = 1; */
        if (message.idToken !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.idToken);
        const u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message entrum.lite.v1.DeleteRequest
 */
export const DeleteRequest = new DeleteRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteResponse$Type extends MessageType<DeleteResponse> {
    constructor() {
        super("entrum.lite.v1.DeleteResponse", []);
    }
    create(value?: PartialMessage<DeleteResponse>): DeleteResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<DeleteResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteResponse): DeleteResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DeleteResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        const u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message entrum.lite.v1.DeleteResponse
 */
export const DeleteResponse = new DeleteResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VerificationEmailRequest$Type extends MessageType<VerificationEmailRequest> {
    constructor() {
        super("entrum.lite.v1.VerificationEmailRequest", [
            { no: 1, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<VerificationEmailRequest>): VerificationEmailRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.email = "";
        if (value !== undefined)
            reflectionMergePartial<VerificationEmailRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: VerificationEmailRequest): VerificationEmailRequest {
        const message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            const [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string email */ 1:
                    message.email = reader.string();
                    break;
                default:
                    const u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    const d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: VerificationEmailRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string email = 1; */
        if (message.email !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.email);
        const u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message entrum.lite.v1.VerificationEmailRequest
 */
export const VerificationEmailRequest = new VerificationEmailRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VerificationEmailResponse$Type extends MessageType<VerificationEmailResponse> {
    constructor() {
        super("entrum.lite.v1.VerificationEmailResponse", [
            { no: 1, name: "transaction_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "transaction_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "expires_at", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<VerificationEmailResponse>): VerificationEmailResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.transactionId = "";
        message.transactionKey = "";
        if (value !== undefined)
            reflectionMergePartial<VerificationEmailResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: VerificationEmailResponse): VerificationEmailResponse {
        const message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            const [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string transaction_id */ 1:
                    message.transactionId = reader.string();
                    break;
                case /* string transaction_key */ 2:
                    message.transactionKey = reader.string();
                    break;
                case /* google.protobuf.Timestamp expires_at */ 3:
                    message.expiresAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.expiresAt);
                    break;
                default:
                    const u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    const d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: VerificationEmailResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string transaction_id = 1; */
        if (message.transactionId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.transactionId);
        /* string transaction_key = 2; */
        if (message.transactionKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.transactionKey);
        /* google.protobuf.Timestamp expires_at = 3; */
        if (message.expiresAt)
            Timestamp.internalBinaryWrite(message.expiresAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        const u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message entrum.lite.v1.VerificationEmailResponse
 */
export const VerificationEmailResponse = new VerificationEmailResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VerificationEmailConfirmRequest$Type extends MessageType<VerificationEmailConfirmRequest> {
    constructor() {
        super("entrum.lite.v1.VerificationEmailConfirmRequest", [
            { no: 1, name: "transaction_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "transaction_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<VerificationEmailConfirmRequest>): VerificationEmailConfirmRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.transactionId = "";
        message.transactionKey = "";
        message.code = "";
        if (value !== undefined)
            reflectionMergePartial<VerificationEmailConfirmRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: VerificationEmailConfirmRequest): VerificationEmailConfirmRequest {
        const message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            const [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string transaction_id */ 1:
                    message.transactionId = reader.string();
                    break;
                case /* string transaction_key */ 2:
                    message.transactionKey = reader.string();
                    break;
                case /* string code */ 3:
                    message.code = reader.string();
                    break;
                default:
                    const u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    const d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: VerificationEmailConfirmRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string transaction_id = 1; */
        if (message.transactionId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.transactionId);
        /* string transaction_key = 2; */
        if (message.transactionKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.transactionKey);
        /* string code = 3; */
        if (message.code !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.code);
        const u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message entrum.lite.v1.VerificationEmailConfirmRequest
 */
export const VerificationEmailConfirmRequest = new VerificationEmailConfirmRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VerificationEmailConfirmResponse$Type extends MessageType<VerificationEmailConfirmResponse> {
    constructor() {
        super("entrum.lite.v1.VerificationEmailConfirmResponse", [
            { no: 1, name: "certificate_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "expires_at", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<VerificationEmailConfirmResponse>): VerificationEmailConfirmResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.certificateId = "";
        if (value !== undefined)
            reflectionMergePartial<VerificationEmailConfirmResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: VerificationEmailConfirmResponse): VerificationEmailConfirmResponse {
        const message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            const [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string certificate_id */ 1:
                    message.certificateId = reader.string();
                    break;
                case /* google.protobuf.Timestamp expires_at */ 2:
                    message.expiresAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.expiresAt);
                    break;
                default:
                    const u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    const d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: VerificationEmailConfirmResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string certificate_id = 1; */
        if (message.certificateId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.certificateId);
        /* google.protobuf.Timestamp expires_at = 2; */
        if (message.expiresAt)
            Timestamp.internalBinaryWrite(message.expiresAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        const u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message entrum.lite.v1.VerificationEmailConfirmResponse
 */
export const VerificationEmailConfirmResponse = new VerificationEmailConfirmResponse$Type();
/**
 * @generated ServiceType for protobuf service entrum.lite.v1.LiteApi
 */
export const LiteApi = new ServiceType("entrum.lite.v1.LiteApi", [
    { name: "SignIn", options: {}, I: SignInRequest, O: SignInResponse },
    { name: "SignOut", options: {}, I: SignOutRequest, O: SignOutResponse },
    { name: "Refresh", options: {}, I: RefreshRequest, O: RefreshResponse },
    { name: "Delete", options: {}, I: DeleteRequest, O: DeleteResponse },
    { name: "VerificationEmail", options: {}, I: VerificationEmailRequest, O: VerificationEmailResponse },
    { name: "VerificationEmailConfirm", options: {}, I: VerificationEmailConfirmRequest, O: VerificationEmailConfirmResponse }
]);
