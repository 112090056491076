import { GrpcWebFetchTransport } from '@protobuf-ts/grpcweb-transport'
import { ServiceFoodhuntUrl } from 'const'
import { RpcOptions } from '@protobuf-ts/runtime-rpc'
import {
	GetRestaurantFoodFeedbackByIdRequest,
	GetRestaurantFoodFeedbackByIdResponse,
	GetRestaurantFoodFeedbacksRequest,
	GetRestaurantFoodFeedbacksResponse,
	GetRestaurantsRequest,
	GetRestaurantsResponse,
	RestaurantFilters,
	RestaurantSearch,
	RestaurantFoodFeedbackStatus,
	GetRestaurantByIdRequest,
	GetRestaurantByIdResponse,
	UpdateRestaurantRequest,
	RestaurantType,
	RestaurantStatus,
	UpdateRestaurantResponse,
} from 'grpc/gen/admin/v1/admin'
import { AdminAPIClient } from 'grpc/gen/admin/v1/admin.client'
import {
	RestaurantFoodFeedback,
	RestaurantFood,
	RestaurantPoint,
	GetRestaurantPointByIdRequest,
	GetRestaurantFoodByIdRequest,
	GetRestaurantPointByIdResponse,
	GetRestaurantFoodByIdResponse,
} from 'grpc/gen/admin/v1/admin'
import { getResponseFromCall } from 'grpc/utils/response'
import AuthorizationInterceptor from 'grpc/interceptors/authInterceptor'
import VerifyAndRefreshTokenInterceptor from 'grpc/interceptors/refreshTokenInterceptor'

export default class AdminRepository {
	private client: AdminAPIClient
	private options: RpcOptions

	constructor() {
		this.client = new AdminAPIClient(
			new GrpcWebFetchTransport({
				baseUrl: ServiceFoodhuntUrl,
			}),
		)
		this.options = {
			interceptors: [AuthorizationInterceptor()],
		}
	}

	public async getRestaurants(filters: RestaurantFilters, page: number, pageSize: number): Promise<RestaurantSearch[]> {
		await VerifyAndRefreshTokenInterceptor()
		const request = GetRestaurantsRequest.fromJson({ page: page, pageSize: pageSize })
		request.filters = filters
		const response = (await getResponseFromCall(
			this.client.getRestaurants(request, this.options),
		)) as GetRestaurantsResponse
		return response.items
	}

	public async getRestaurantById(id: number): Promise<RestaurantSearch | null> {
		await VerifyAndRefreshTokenInterceptor()
		const request = GetRestaurantByIdRequest.fromJson({ restaurantId: id })
		const response = (await getResponseFromCall(
			this.client.getRestaurantById(request, this.options),
		)) as GetRestaurantByIdResponse
		return response.item ?? null
	}

	public async getRestaurantFoodFeedback(
		status: RestaurantFoodFeedbackStatus,
		feedbackIds: number[] | null,
		page: number,
		pageSize: number,
	): Promise<RestaurantFoodFeedback[]> {
		await VerifyAndRefreshTokenInterceptor()
		const request = GetRestaurantFoodFeedbacksRequest.fromJson({
			status: status,
			feedbackIds: feedbackIds,
			page: page,
			pageSize: pageSize,
		})
		const response = (await getResponseFromCall(
			this.client.getRestaurantFoodFeedbacks(request, this.options),
		)) as GetRestaurantFoodFeedbacksResponse
		return response.items
	}

	public async getRestaurantPointById(pointId: number): Promise<RestaurantPoint | null> {
		await VerifyAndRefreshTokenInterceptor()
		const request = GetRestaurantPointByIdRequest.fromJson({ pointId: pointId })
		const response = (await getResponseFromCall(
			this.client.getRestaurantPointById(request, this.options),
		)) as GetRestaurantPointByIdResponse
		return response.item ?? null
	}

	public async getRestaurantFoodById(foodId: number): Promise<RestaurantFood | null> {
		await VerifyAndRefreshTokenInterceptor()
		const request = GetRestaurantFoodByIdRequest.fromJson({ foodId: foodId })
		const response = (await getResponseFromCall(
			this.client.getRestaurantFoodById(request, this.options),
		)) as GetRestaurantFoodByIdResponse
		return response.item ?? null
	}

	public async getRestaurantFoodFeedbackById(feedbackId: number): Promise<RestaurantFoodFeedback | null> {
		await VerifyAndRefreshTokenInterceptor()
		const request = GetRestaurantFoodFeedbackByIdRequest.fromJson({ feedbackId: feedbackId })
		const response = (await getResponseFromCall(
			this.client.getRestaurantFoodFeedbackById(request, this.options),
		)) as GetRestaurantFoodFeedbackByIdResponse
		return response.item ?? null
	}

	public async updateRestaurant(
		id: number,
		name: string,
		fileId: number,
		type: RestaurantType,
		status: RestaurantStatus,
	): Promise<number> {
		await VerifyAndRefreshTokenInterceptor()
		const request = UpdateRestaurantRequest.fromJson({ id: id, name: name, fileId: fileId, type: type, status: status })
		await getResponseFromCall(this.client.updateRestaurant(request, this.options))
		return id
	}
}
