import { decodeJwt } from 'jose'

export default class AccessToken {
	private accessToken: string

	public constructor(accessToken: string) {
		this.accessToken = accessToken
	}

	public getRaw(): string {
		return this.accessToken
	}

	public isValidAdminToken(): boolean {
		let claims = null
		// проверяем что токен валидный
		try {
			claims = decodeJwt(this.accessToken)
		} catch {
			return false
		}

		// проверяем не протух ли токен
		if (Date.now() / 1000 >= claims.exp) {
			return false
		}

		return claims.user.role === 'admin'
	}
}
