import { Status } from 'grpc/gen/google/rpc/status'
import { base64decode } from '@protobuf-ts/runtime'
import { RpcMetadata, UnaryCall } from '@protobuf-ts/runtime-rpc'
import { RpcError } from '@protobuf-ts/runtime-rpc'
import { Error } from 'grpc/gen/overall/v1/overall'
import ErrorGRPC from 'domain/entity/grpc/models/ErrorGrpc'

export function richStatusFromRpcMetadata(meta: RpcMetadata): Status | undefined {
	const ref = meta['grpc-status-details-bin']
	if (!ref) return
	const b64StatusBin = typeof ref === 'string' ? ref : ref[ref.length - 1]
	return Status.fromBinary(base64decode(b64StatusBin))
}

export async function getResponseFromCall(call: UnaryCall): Promise<object> {
	try {
		return await call.response
	} catch (error) {
		if (error instanceof RpcError) {
			const err = getGrpcErrorFromRpcErrorMaybe(error as RpcError)
			if (err !== null) {
				throw err
			}
		}
		throw error
	}
}

function getGrpcErrorFromRpcErrorMaybe(error: RpcError): ErrorGRPC | null {
	try {
		const status = richStatusFromRpcMetadata(error.meta)
		if (!status) return null
		const err = Error.fromBinary(status?.details.at(0)?.value as Uint8Array)
		return new ErrorGRPC(err.code, err.message)
	} catch (error) {
		console.log(error)
	}
	return null
}
