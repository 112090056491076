import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card'
import { formatRestaurantStatus, formatRestaurantType } from 'helpers/RestaurantHelper'
import { formatUrl } from 'helpers/UrlHelper'
import { ChevronLeft } from 'lucide-react'
import { observer } from 'mobx-react'
import RestaurantViewModel from 'presentation/viewmodel/restaurant/RestaurantViewModel'
import React from 'react'

export interface RestaurantComponentProps {
	viewModel: RestaurantViewModel
}

@observer
export default class RestaurantComponent extends React.Component<RestaurantComponentProps> {
	public render(): React.ReactNode {
		const { restaurant } = this.props.viewModel
		return (
			<main className='grid flex-1 items-start gap-4 p-4 sm:px-6 md:py-6 md:gap-8'>
				<div className='mx-auto grid max-w-[59rem] flex-1 auto-rows-max gap-4'>
					<div className='flex items-center gap-4'>
						<Button
							onClick={() => this.props.viewModel.onClickDiscardRestaurant()}
							variant='outline'
							size='icon'
							className='h-7 w-7'>
							<ChevronLeft className='h-4 w-4' />
							<span className='sr-only'>Back</span>
						</Button>
						<h1 className='flex-1 shrink-0 whitespace-nowrap text-xl font-semibold tracking-tight sm:grow-0'>
							{restaurant?.name}
						</h1>
						<Badge variant='outline' className='ml-auto sm:ml-0'>
							{formatRestaurantStatus(0)}
						</Badge>
						<div className='hidden items-center gap-2 md:ml-auto md:flex'>
							<Button onClick={async () => await this.props.viewModel.onClickEditRestaurant()} size='sm'>
								Редактировать
							</Button>
						</div>
					</div>
					<div className='grid gap-4 md:grid-cols-[1fr_250px] lg:grid-cols-3 lg:gap-8'>
						<div className='grid auto-rows-max items-start gap-4 lg:col-span-2 lg:gap-8'>
							<Card x-chunk='dashboard-07-chunk-0'>
								<img className='rounded-md' src={formatUrl(restaurant?.url ?? '')} />
							</Card>
						</div>
						<div className='grid auto-rows-max items-start gap-4 lg:gap-8'>
							<Card x-chunk='dashboard-07-chunk-5'>
								<CardContent className='p-6 text-sm'>
									<div className='grid gap-3'>
										<div className='font-semibold'>Основная информация</div>
										<ul className='grid gap-3'>
											<li className='flex items-center justify-between'>
												<span className='text-muted-foreground'>ID организации</span>
												<span>{Number(restaurant?.id)}</span>
											</li>
											<li className='flex items-center justify-between'>
												<span className='text-muted-foreground'>Тип организации</span>
												<span>{formatRestaurantType(restaurant?.type ?? 0)}</span>
											</li>
										</ul>
									</div>
								</CardContent>
							</Card>
						</div>
					</div>
					<div className='flex items-center justify-center gap-2 md:hidden'>
						<Button onClick={async () => await this.props.viewModel.onClickEditRestaurant()} size='sm'>
							Редактировать
						</Button>
					</div>
				</div>
			</main>
		)
	}
}
