import { ChangeEvent } from 'react'

function getImageData(event: ChangeEvent<HTMLInputElement>) {
	// FileList is immutable, so we need to create a new one
	const dataTransfer = new DataTransfer()

	// Add newly uploaded images
	Array.from(event.target.files!).forEach(image => dataTransfer.items.add(image))

	const files = dataTransfer.files
	const displayUrl = URL.createObjectURL(event.target.files![0])

	return { files, displayUrl }
}

export { getImageData }
