// @generated by protobuf-ts 2.9.4
// @generated from protobuf file "admin/v1/admin.proto" (package "foodhunt.admin.v1", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { AdminAPI } from "./admin";
import type { UpdateRestaurantFoodResponse } from "./admin";
import type { UpdateRestaurantFoodRequest } from "./admin";
import type { UpdateRestaurantPointResponse } from "./admin";
import type { UpdateRestaurantPointRequest } from "./admin";
import type { UpdateRestaurantResponse } from "./admin";
import type { UpdateRestaurantRequest } from "./admin";
import type { AttachFileToRestaurantResponse } from "./admin";
import type { AttachFileToRestaurantRequest } from "./admin";
import type { GetRestaurantFoodFeedbackByIdResponse } from "./admin";
import type { GetRestaurantFoodFeedbackByIdRequest } from "./admin";
import type { GetRestaurantFoodByIdResponse } from "./admin";
import type { GetRestaurantFoodByIdRequest } from "./admin";
import type { GetRestaurantPointByIdResponse } from "./admin";
import type { GetRestaurantPointByIdRequest } from "./admin";
import type { GetRestaurantFoodFeedbacksResponse } from "./admin";
import type { GetRestaurantFoodFeedbacksRequest } from "./admin";
import type { GetRestaurantFoodsResponse } from "./admin";
import type { GetRestaurantFoodsRequest } from "./admin";
import type { GetRestaurantPointsResponse } from "./admin";
import type { GetRestaurantPointsRequest } from "./admin";
import type { GetRestaurantByIdResponse } from "./admin";
import type { GetRestaurantByIdRequest } from "./admin";
import type { GetRestaurantsResponse } from "./admin";
import type { GetRestaurantsRequest } from "./admin";
import type { AcceptRestaurantFoodFeedbackResponse } from "./admin";
import type { AcceptRestaurantFoodFeedbackdRequest } from "./admin";
import type { AcceptRestaurantFoodResponse } from "./admin";
import type { AcceptRestaurantFoodRequest } from "./admin";
import type { AcceptRestaurantPointResponse } from "./admin";
import type { AcceptRestaurantPointRequest } from "./admin";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { AcceptRestaurantResponse } from "./admin";
import type { AcceptRestaurantRequest } from "./admin";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service foodhunt.admin.v1.AdminAPI
 */
export interface IAdminAPIClient {
    /**
     * @generated from protobuf rpc: AcceptRestaurant(foodhunt.admin.v1.AcceptRestaurantRequest) returns (foodhunt.admin.v1.AcceptRestaurantResponse);
     */
    acceptRestaurant(input: AcceptRestaurantRequest, options?: RpcOptions): UnaryCall<AcceptRestaurantRequest, AcceptRestaurantResponse>;
    /**
     * @generated from protobuf rpc: AcceptRestaurantPoint(foodhunt.admin.v1.AcceptRestaurantPointRequest) returns (foodhunt.admin.v1.AcceptRestaurantPointResponse);
     */
    acceptRestaurantPoint(input: AcceptRestaurantPointRequest, options?: RpcOptions): UnaryCall<AcceptRestaurantPointRequest, AcceptRestaurantPointResponse>;
    /**
     * @generated from protobuf rpc: AcceptRestaurantFood(foodhunt.admin.v1.AcceptRestaurantFoodRequest) returns (foodhunt.admin.v1.AcceptRestaurantFoodResponse);
     */
    acceptRestaurantFood(input: AcceptRestaurantFoodRequest, options?: RpcOptions): UnaryCall<AcceptRestaurantFoodRequest, AcceptRestaurantFoodResponse>;
    /**
     * @generated from protobuf rpc: AcceptRestaurantFoodFeedback(foodhunt.admin.v1.AcceptRestaurantFoodFeedbackdRequest) returns (foodhunt.admin.v1.AcceptRestaurantFoodFeedbackResponse);
     */
    acceptRestaurantFoodFeedback(input: AcceptRestaurantFoodFeedbackdRequest, options?: RpcOptions): UnaryCall<AcceptRestaurantFoodFeedbackdRequest, AcceptRestaurantFoodFeedbackResponse>;
    /**
     * @generated from protobuf rpc: GetRestaurants(foodhunt.admin.v1.GetRestaurantsRequest) returns (foodhunt.admin.v1.GetRestaurantsResponse);
     */
    getRestaurants(input: GetRestaurantsRequest, options?: RpcOptions): UnaryCall<GetRestaurantsRequest, GetRestaurantsResponse>;
    /**
     * @generated from protobuf rpc: GetRestaurantById(foodhunt.admin.v1.GetRestaurantByIdRequest) returns (foodhunt.admin.v1.GetRestaurantByIdResponse);
     */
    getRestaurantById(input: GetRestaurantByIdRequest, options?: RpcOptions): UnaryCall<GetRestaurantByIdRequest, GetRestaurantByIdResponse>;
    /**
     * @generated from protobuf rpc: GetRestaurantPoints(foodhunt.admin.v1.GetRestaurantPointsRequest) returns (foodhunt.admin.v1.GetRestaurantPointsResponse);
     */
    getRestaurantPoints(input: GetRestaurantPointsRequest, options?: RpcOptions): UnaryCall<GetRestaurantPointsRequest, GetRestaurantPointsResponse>;
    /**
     * @generated from protobuf rpc: GetRestaurantFoods(foodhunt.admin.v1.GetRestaurantFoodsRequest) returns (foodhunt.admin.v1.GetRestaurantFoodsResponse);
     */
    getRestaurantFoods(input: GetRestaurantFoodsRequest, options?: RpcOptions): UnaryCall<GetRestaurantFoodsRequest, GetRestaurantFoodsResponse>;
    /**
     * @generated from protobuf rpc: GetRestaurantFoodFeedbacks(foodhunt.admin.v1.GetRestaurantFoodFeedbacksRequest) returns (foodhunt.admin.v1.GetRestaurantFoodFeedbacksResponse);
     */
    getRestaurantFoodFeedbacks(input: GetRestaurantFoodFeedbacksRequest, options?: RpcOptions): UnaryCall<GetRestaurantFoodFeedbacksRequest, GetRestaurantFoodFeedbacksResponse>;
    /**
     * @generated from protobuf rpc: GetRestaurantPointById(foodhunt.admin.v1.GetRestaurantPointByIdRequest) returns (foodhunt.admin.v1.GetRestaurantPointByIdResponse);
     */
    getRestaurantPointById(input: GetRestaurantPointByIdRequest, options?: RpcOptions): UnaryCall<GetRestaurantPointByIdRequest, GetRestaurantPointByIdResponse>;
    /**
     * @generated from protobuf rpc: GetRestaurantFoodById(foodhunt.admin.v1.GetRestaurantFoodByIdRequest) returns (foodhunt.admin.v1.GetRestaurantFoodByIdResponse);
     */
    getRestaurantFoodById(input: GetRestaurantFoodByIdRequest, options?: RpcOptions): UnaryCall<GetRestaurantFoodByIdRequest, GetRestaurantFoodByIdResponse>;
    /**
     * @generated from protobuf rpc: GetRestaurantFoodFeedbackById(foodhunt.admin.v1.GetRestaurantFoodFeedbackByIdRequest) returns (foodhunt.admin.v1.GetRestaurantFoodFeedbackByIdResponse);
     */
    getRestaurantFoodFeedbackById(input: GetRestaurantFoodFeedbackByIdRequest, options?: RpcOptions): UnaryCall<GetRestaurantFoodFeedbackByIdRequest, GetRestaurantFoodFeedbackByIdResponse>;
    /**
     * @generated from protobuf rpc: AttachFileToRestaurant(foodhunt.admin.v1.AttachFileToRestaurantRequest) returns (foodhunt.admin.v1.AttachFileToRestaurantResponse);
     */
    attachFileToRestaurant(input: AttachFileToRestaurantRequest, options?: RpcOptions): UnaryCall<AttachFileToRestaurantRequest, AttachFileToRestaurantResponse>;
    /**
     * @generated from protobuf rpc: UpdateRestaurant(foodhunt.admin.v1.UpdateRestaurantRequest) returns (foodhunt.admin.v1.UpdateRestaurantResponse);
     */
    updateRestaurant(input: UpdateRestaurantRequest, options?: RpcOptions): UnaryCall<UpdateRestaurantRequest, UpdateRestaurantResponse>;
    /**
     * @generated from protobuf rpc: UpdateRestaurantPoint(foodhunt.admin.v1.UpdateRestaurantPointRequest) returns (foodhunt.admin.v1.UpdateRestaurantPointResponse);
     */
    updateRestaurantPoint(input: UpdateRestaurantPointRequest, options?: RpcOptions): UnaryCall<UpdateRestaurantPointRequest, UpdateRestaurantPointResponse>;
    /**
     * @generated from protobuf rpc: UpdateRestaurantFood(foodhunt.admin.v1.UpdateRestaurantFoodRequest) returns (foodhunt.admin.v1.UpdateRestaurantFoodResponse);
     */
    updateRestaurantFood(input: UpdateRestaurantFoodRequest, options?: RpcOptions): UnaryCall<UpdateRestaurantFoodRequest, UpdateRestaurantFoodResponse>;
}
/**
 * @generated from protobuf service foodhunt.admin.v1.AdminAPI
 */
export class AdminAPIClient implements IAdminAPIClient, ServiceInfo {
    typeName = AdminAPI.typeName;
    methods = AdminAPI.methods;
    options = AdminAPI.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: AcceptRestaurant(foodhunt.admin.v1.AcceptRestaurantRequest) returns (foodhunt.admin.v1.AcceptRestaurantResponse);
     */
    acceptRestaurant(input: AcceptRestaurantRequest, options?: RpcOptions): UnaryCall<AcceptRestaurantRequest, AcceptRestaurantResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<AcceptRestaurantRequest, AcceptRestaurantResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: AcceptRestaurantPoint(foodhunt.admin.v1.AcceptRestaurantPointRequest) returns (foodhunt.admin.v1.AcceptRestaurantPointResponse);
     */
    acceptRestaurantPoint(input: AcceptRestaurantPointRequest, options?: RpcOptions): UnaryCall<AcceptRestaurantPointRequest, AcceptRestaurantPointResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<AcceptRestaurantPointRequest, AcceptRestaurantPointResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: AcceptRestaurantFood(foodhunt.admin.v1.AcceptRestaurantFoodRequest) returns (foodhunt.admin.v1.AcceptRestaurantFoodResponse);
     */
    acceptRestaurantFood(input: AcceptRestaurantFoodRequest, options?: RpcOptions): UnaryCall<AcceptRestaurantFoodRequest, AcceptRestaurantFoodResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<AcceptRestaurantFoodRequest, AcceptRestaurantFoodResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: AcceptRestaurantFoodFeedback(foodhunt.admin.v1.AcceptRestaurantFoodFeedbackdRequest) returns (foodhunt.admin.v1.AcceptRestaurantFoodFeedbackResponse);
     */
    acceptRestaurantFoodFeedback(input: AcceptRestaurantFoodFeedbackdRequest, options?: RpcOptions): UnaryCall<AcceptRestaurantFoodFeedbackdRequest, AcceptRestaurantFoodFeedbackResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<AcceptRestaurantFoodFeedbackdRequest, AcceptRestaurantFoodFeedbackResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetRestaurants(foodhunt.admin.v1.GetRestaurantsRequest) returns (foodhunt.admin.v1.GetRestaurantsResponse);
     */
    getRestaurants(input: GetRestaurantsRequest, options?: RpcOptions): UnaryCall<GetRestaurantsRequest, GetRestaurantsResponse> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetRestaurantsRequest, GetRestaurantsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetRestaurantById(foodhunt.admin.v1.GetRestaurantByIdRequest) returns (foodhunt.admin.v1.GetRestaurantByIdResponse);
     */
    getRestaurantById(input: GetRestaurantByIdRequest, options?: RpcOptions): UnaryCall<GetRestaurantByIdRequest, GetRestaurantByIdResponse> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetRestaurantByIdRequest, GetRestaurantByIdResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetRestaurantPoints(foodhunt.admin.v1.GetRestaurantPointsRequest) returns (foodhunt.admin.v1.GetRestaurantPointsResponse);
     */
    getRestaurantPoints(input: GetRestaurantPointsRequest, options?: RpcOptions): UnaryCall<GetRestaurantPointsRequest, GetRestaurantPointsResponse> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetRestaurantPointsRequest, GetRestaurantPointsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetRestaurantFoods(foodhunt.admin.v1.GetRestaurantFoodsRequest) returns (foodhunt.admin.v1.GetRestaurantFoodsResponse);
     */
    getRestaurantFoods(input: GetRestaurantFoodsRequest, options?: RpcOptions): UnaryCall<GetRestaurantFoodsRequest, GetRestaurantFoodsResponse> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetRestaurantFoodsRequest, GetRestaurantFoodsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetRestaurantFoodFeedbacks(foodhunt.admin.v1.GetRestaurantFoodFeedbacksRequest) returns (foodhunt.admin.v1.GetRestaurantFoodFeedbacksResponse);
     */
    getRestaurantFoodFeedbacks(input: GetRestaurantFoodFeedbacksRequest, options?: RpcOptions): UnaryCall<GetRestaurantFoodFeedbacksRequest, GetRestaurantFoodFeedbacksResponse> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetRestaurantFoodFeedbacksRequest, GetRestaurantFoodFeedbacksResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetRestaurantPointById(foodhunt.admin.v1.GetRestaurantPointByIdRequest) returns (foodhunt.admin.v1.GetRestaurantPointByIdResponse);
     */
    getRestaurantPointById(input: GetRestaurantPointByIdRequest, options?: RpcOptions): UnaryCall<GetRestaurantPointByIdRequest, GetRestaurantPointByIdResponse> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetRestaurantPointByIdRequest, GetRestaurantPointByIdResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetRestaurantFoodById(foodhunt.admin.v1.GetRestaurantFoodByIdRequest) returns (foodhunt.admin.v1.GetRestaurantFoodByIdResponse);
     */
    getRestaurantFoodById(input: GetRestaurantFoodByIdRequest, options?: RpcOptions): UnaryCall<GetRestaurantFoodByIdRequest, GetRestaurantFoodByIdResponse> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetRestaurantFoodByIdRequest, GetRestaurantFoodByIdResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetRestaurantFoodFeedbackById(foodhunt.admin.v1.GetRestaurantFoodFeedbackByIdRequest) returns (foodhunt.admin.v1.GetRestaurantFoodFeedbackByIdResponse);
     */
    getRestaurantFoodFeedbackById(input: GetRestaurantFoodFeedbackByIdRequest, options?: RpcOptions): UnaryCall<GetRestaurantFoodFeedbackByIdRequest, GetRestaurantFoodFeedbackByIdResponse> {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetRestaurantFoodFeedbackByIdRequest, GetRestaurantFoodFeedbackByIdResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: AttachFileToRestaurant(foodhunt.admin.v1.AttachFileToRestaurantRequest) returns (foodhunt.admin.v1.AttachFileToRestaurantResponse);
     */
    attachFileToRestaurant(input: AttachFileToRestaurantRequest, options?: RpcOptions): UnaryCall<AttachFileToRestaurantRequest, AttachFileToRestaurantResponse> {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept<AttachFileToRestaurantRequest, AttachFileToRestaurantResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateRestaurant(foodhunt.admin.v1.UpdateRestaurantRequest) returns (foodhunt.admin.v1.UpdateRestaurantResponse);
     */
    updateRestaurant(input: UpdateRestaurantRequest, options?: RpcOptions): UnaryCall<UpdateRestaurantRequest, UpdateRestaurantResponse> {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateRestaurantRequest, UpdateRestaurantResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateRestaurantPoint(foodhunt.admin.v1.UpdateRestaurantPointRequest) returns (foodhunt.admin.v1.UpdateRestaurantPointResponse);
     */
    updateRestaurantPoint(input: UpdateRestaurantPointRequest, options?: RpcOptions): UnaryCall<UpdateRestaurantPointRequest, UpdateRestaurantPointResponse> {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateRestaurantPointRequest, UpdateRestaurantPointResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateRestaurantFood(foodhunt.admin.v1.UpdateRestaurantFoodRequest) returns (foodhunt.admin.v1.UpdateRestaurantFoodResponse);
     */
    updateRestaurantFood(input: UpdateRestaurantFoodRequest, options?: RpcOptions): UnaryCall<UpdateRestaurantFoodRequest, UpdateRestaurantFoodResponse> {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateRestaurantFoodRequest, UpdateRestaurantFoodResponse>("unary", this._transport, method, opt, input);
    }
}
