import axios from 'axios'
import { ServiceStorageUrl } from 'const'
import UploadFile from 'domain/entity/storage/models/UploadFile'

export default class StorageRepository {
	public async uploadFile(file: File, accessToken: string): Promise<UploadFile | null> {
		const formData = new FormData()
		formData.append('file', file)
		const resp = await axios.post(ServiceStorageUrl, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: accessToken,
			},
		})
		if (resp.status != 200) {
			return null
		}
		const { data } = resp
		return new UploadFile(data.data['file_id'], data.data['url'])
	}
}
