import UploadFile from 'domain/entity/storage/models/UploadFile'
import SessionRepository from 'domain/repository/session/SessionRepository'
import StorageRepository from 'domain/repository/storage/StorageRepository'

export default class StorageUseCase {
	private repository: StorageRepository
	private sessionRepository: SessionRepository

	constructor(repository: StorageRepository, sessionRepository: SessionRepository) {
		this.repository = repository
		this.sessionRepository = sessionRepository
	}

	public async uploadFile(file: File): Promise<UploadFile | null> {
		return await this.repository.uploadFile(file, this.sessionRepository.getAccessTokenRaw() ?? '')
	}
}
