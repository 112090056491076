// @generated by protobuf-ts 2.9.4
// @generated from protobuf file "overall/v1/overall.proto" (package "entrum.overall.v1", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * Ошибка, содержащая дополнительные данные
 *
 * @generated from protobuf message entrum.overall.v1.Error
 */
export interface Error {
    /**
     * Код ошибки.
     *
     * @generated from protobuf field: entrum.overall.v1.ErrorCode code = 1;
     */
    code: ErrorCode;
    /**
     * Сообщение для пользователя об ошибке
     *
     * @generated from protobuf field: string message = 2;
     */
    message: string;
}
/**
 * Код ошибки.
 *
 * @generated from protobuf enum entrum.overall.v1.ErrorCode
 */
export enum ErrorCode {
    /**
     * неизвестный код ошибки
     *
     * @generated from protobuf enum value: ERROR_CODE_UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * неверные параметры запроса
     *
     * @generated from protobuf enum value: ERROR_CODE_INVALID_PARAMETER = 1;
     */
    INVALID_PARAMETER = 1,
    /**
     * неверный телефон или пароль
     *
     * @generated from protobuf enum value: ERROR_CODE_INVALID_PHONE_PASSWORD = 2;
     */
    INVALID_PHONE_PASSWORD = 2,
    /**
     * неверный сертификат
     *
     * @generated from protobuf enum value: ERROR_CODE_INVALID_CERTIFICATE = 3;
     */
    INVALID_CERTIFICATE = 3,
    /**
     * ошибка верификации
     *
     * @generated from protobuf enum value: ERROR_CODE_VERIFICATION_ENVIRONMENT_ERROR = 4;
     */
    VERIFICATION_ENVIRONMENT_ERROR = 4,
    /**
     * пароль для входа задан неверно
     *
     * @generated from protobuf enum value: ERROR_CODE_WRONG_PASSWORD = 5;
     */
    WRONG_PASSWORD = 5,
    /**
     * пользователь существует
     *
     * @generated from protobuf enum value: ERROR_CODE_USER_EXIST = 6;
     */
    USER_EXIST = 6,
    /**
     * невалидный access token
     *
     * @generated from protobuf enum value: ERROR_CODE_INVALID_ACCESS_TOKEN = 7;
     */
    INVALID_ACCESS_TOKEN = 7,
    /**
     * невалидный refresh token
     *
     * @generated from protobuf enum value: ERROR_CODE_INVALID_REFRESH_TOKEN = 8;
     */
    INVALID_REFRESH_TOKEN = 8,
    /**
     * неверный пользователь
     *
     * @generated from protobuf enum value: ERROR_CODE_INVALID_USER = 9;
     */
    INVALID_USER = 9,
    /**
     * превышено максимальное число запросов
     *
     * @generated from protobuf enum value: ERROR_CODE_TOO_MANY_REQUEST = 10;
     */
    TOO_MANY_REQUEST = 10,
    /**
     * верификация не найдена, не понимаю зачем она нужна, но пусть будет
     * в любом случае это можно обработать как internal error, а не сообщать клиенту лишнюю информациб
     * поскольку ничего не сможет с этим поделать, а для злоумышленника лишняя информация
     *
     * @generated from protobuf enum value: ERROR_CODE_VERIFICATION_NOT_EXIST = 11;
     */
    VERIFICATION_NOT_EXIST = 11,
    /**
     * введен ошибочный код подтверждения
     *
     * @generated from protobuf enum value: ERROR_CODE_WRONG_CODE = 12;
     */
    WRONG_CODE = 12,
    /**
     * невалидный access token claims
     *
     * @generated from protobuf enum value: ERROR_CODE_INVALID_ACCESS_TOKEN_CLAIMS = 13;
     */
    INVALID_ACCESS_TOKEN_CLAIMS = 13,
    /**
     * протух access token
     *
     * @generated from protobuf enum value: ERROR_CODE_ACCESS_TOKEN_EXPIRED = 14;
     */
    ACCESS_TOKEN_EXPIRED = 14
}
// @generated message type with reflection information, may provide speed optimized methods
class Error$Type extends MessageType<Error> {
    constructor() {
        super("entrum.overall.v1.Error", [
            { no: 1, name: "code", kind: "enum", T: () => ["entrum.overall.v1.ErrorCode", ErrorCode, "ERROR_CODE_"] },
            { no: 2, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Error>): Error {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.code = 0;
        message.message = "";
        if (value !== undefined)
            reflectionMergePartial<Error>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Error): Error {
        const message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            const [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* entrum.overall.v1.ErrorCode code */ 1:
                    message.code = reader.int32();
                    break;
                case /* string message */ 2:
                    message.message = reader.string();
                    break;
                default:
                    const u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    const d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Error, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* entrum.overall.v1.ErrorCode code = 1; */
        if (message.code !== 0)
            writer.tag(1, WireType.Varint).int32(message.code);
        /* string message = 2; */
        if (message.message !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.message);
        const u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message entrum.overall.v1.Error
 */
export const Error = new Error$Type();
/**
 * @generated ServiceType for protobuf service entrum.overall.v1.OverallAPI
 */
export const OverallAPI = new ServiceType("entrum.overall.v1.OverallAPI", []);
