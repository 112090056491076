import AdminUseCase from 'domain/usecase/admin/AdminUseCase'
import StorageUseCase from 'domain/usecase/storage/StorageUseCase'
import { RestaurantSearch } from 'grpc/gen/admin/v1/admin'
import { makeObservable, observable, runInAction } from 'mobx'
import { NavigateFunction } from 'react-router-dom'

export default class RestaurantSettingsViewModel {
	@observable public restaurant: RestaurantSearch | null
	@observable public restaurantName: string
	@observable public restaurantUrl: string
	@observable public restaurantFileId: number

	private useCase: AdminUseCase
	private storageUseCase: StorageUseCase
	private navigate: NavigateFunction
	public constructor(useCase: AdminUseCase, storageUseCase: StorageUseCase, navigate: NavigateFunction) {
		this.restaurant = null
		this.restaurantName = ''
		this.restaurantUrl = ''
		this.restaurantFileId = 0
		this.navigate = navigate

		this.useCase = useCase
		this.storageUseCase = storageUseCase
		makeObservable(this)
	}

	public async fetchRestaurant(id: number) {
		const response = await this.useCase.getRestaurantById(id)
		runInAction(() => {
			this.restaurant = response
			this.restaurantName = response?.name ?? ''
			this.restaurantUrl = response?.url ?? ''
		})
	}

	public async uploadFile(files: FileList) {
		const file = files.item(0)
		if (file != null) {
			const uploadedFile = await this.storageUseCase.uploadFile(file)
			if (uploadedFile != null) {
				this.restaurantUrl = uploadedFile.getUrl()
				this.restaurantFileId = uploadedFile.getFileId()
			}
		}
	}

	public onRestaurantNameChanged(name: string) {
		runInAction(() => {
			this.restaurantName = name
		})
	}

	public async onClickSaveRestaurant() {
		if (this.restaurant != null) {
			await this.useCase.updateRestaurant(Number(this.restaurant.id), this.restaurantName, this.restaurantFileId)
		}
		this.navigate(-1)
		return null
	}

	public async onClickDiscardRestaurant() {
		this.navigate(-1)
		return null
	}
}
