// @generated by protobuf-ts 2.9.4
// @generated from protobuf file "admin/v1/admin.proto" (package "foodhunt.admin.v1", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../../google/protobuf/timestamp";
/**
 * @generated from protobuf message foodhunt.admin.v1.AcceptRestaurantRequest
 */
export interface AcceptRestaurantRequest {
    /**
     * @generated from protobuf field: int64 restaurant_id = 1;
     */
    restaurantId: bigint;
}
/**
 * @generated from protobuf message foodhunt.admin.v1.AcceptRestaurantResponse
 */
export interface AcceptRestaurantResponse {
}
/**
 * @generated from protobuf message foodhunt.admin.v1.AcceptRestaurantPointRequest
 */
export interface AcceptRestaurantPointRequest {
    /**
     * @generated from protobuf field: int64 point_id = 1;
     */
    pointId: bigint;
}
/**
 * @generated from protobuf message foodhunt.admin.v1.AcceptRestaurantPointResponse
 */
export interface AcceptRestaurantPointResponse {
}
/**
 * @generated from protobuf message foodhunt.admin.v1.AcceptRestaurantFoodRequest
 */
export interface AcceptRestaurantFoodRequest {
    /**
     * @generated from protobuf field: int64 food_id = 1;
     */
    foodId: bigint;
}
/**
 * @generated from protobuf message foodhunt.admin.v1.AcceptRestaurantFoodResponse
 */
export interface AcceptRestaurantFoodResponse {
}
/**
 * @generated from protobuf message foodhunt.admin.v1.AcceptRestaurantFoodFeedbackdRequest
 */
export interface AcceptRestaurantFoodFeedbackdRequest {
    /**
     * @generated from protobuf field: int64 feedback_id = 1;
     */
    feedbackId: bigint;
}
/**
 * @generated from protobuf message foodhunt.admin.v1.AcceptRestaurantFoodFeedbackResponse
 */
export interface AcceptRestaurantFoodFeedbackResponse {
}
/**
 * @generated from protobuf message foodhunt.admin.v1.RestaurantFilters
 */
export interface RestaurantFilters {
    /**
     * @generated from protobuf field: foodhunt.admin.v1.RestaurantStatus status = 1;
     */
    status: RestaurantStatus;
    /**
     * @generated from protobuf field: foodhunt.admin.v1.RestaurantType type = 2;
     */
    type: RestaurantType;
    /**
     * @generated from protobuf field: repeated int64 restaurant_ids = 3;
     */
    restaurantIds: bigint[];
    /**
     * @generated from protobuf field: string name = 4;
     */
    name: string;
}
/**
 * @generated from protobuf message foodhunt.admin.v1.GetRestaurantsRequest
 */
export interface GetRestaurantsRequest {
    /**
     * @generated from protobuf field: foodhunt.admin.v1.RestaurantFilters filters = 1;
     */
    filters?: RestaurantFilters;
    /**
     * @generated from protobuf field: int32 page = 2;
     */
    page: number;
    /**
     * @generated from protobuf field: int32 page_size = 3;
     */
    pageSize: number;
}
/**
 * @generated from protobuf message foodhunt.admin.v1.RestaurantSearch
 */
export interface RestaurantSearch {
    /**
     * @generated from protobuf field: int64 id = 1;
     */
    id: bigint;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string url = 3;
     */
    url: string;
    /**
     * @generated from protobuf field: foodhunt.admin.v1.RestaurantType type = 4;
     */
    type: RestaurantType;
    /**
     * @generated from protobuf field: foodhunt.admin.v1.RestaurantStatus status = 5;
     */
    status: RestaurantStatus;
}
/**
 * @generated from protobuf message foodhunt.admin.v1.GetRestaurantsResponse
 */
export interface GetRestaurantsResponse {
    /**
     * @generated from protobuf field: repeated foodhunt.admin.v1.RestaurantSearch items = 1;
     */
    items: RestaurantSearch[];
}
/**
 * @generated from protobuf message foodhunt.admin.v1.RestaurantPoint
 */
export interface RestaurantPoint {
    /**
     * @generated from protobuf field: int64 id = 1;
     */
    id: bigint;
    /**
     * @generated from protobuf field: int64 restaurant_id = 2;
     */
    restaurantId: bigint;
    /**
     * @generated from protobuf field: string name = 3;
     */
    name: string;
    /**
     * @generated from protobuf field: string url = 4;
     */
    url: string;
    /**
     * @generated from protobuf field: foodhunt.admin.v1.RestaurantType type = 5;
     */
    type: RestaurantType;
    /**
     * @generated from protobuf field: double latitude = 6;
     */
    latitude: number;
    /**
     * @generated from protobuf field: double longitude = 7;
     */
    longitude: number;
    /**
     * @generated from protobuf field: double rating = 8;
     */
    rating: number;
    /**
     * @generated from protobuf field: int64 review_count = 9;
     */
    reviewCount: bigint;
    /**
     * @generated from protobuf field: int64 distance = 10;
     */
    distance: bigint;
    /**
     * @generated from protobuf field: foodhunt.admin.v1.RestaurantPointStatus status = 11;
     */
    status: RestaurantPointStatus;
}
/**
 * @generated from protobuf message foodhunt.admin.v1.RestaurantPointFilters
 */
export interface RestaurantPointFilters {
    /**
     * @generated from protobuf field: foodhunt.admin.v1.RestaurantPointStatus status = 1;
     */
    status: RestaurantPointStatus;
    /**
     * @generated from protobuf field: repeated int64 point_ids = 2;
     */
    pointIds: bigint[];
}
/**
 * @generated from protobuf message foodhunt.admin.v1.GetRestaurantPointsRequest
 */
export interface GetRestaurantPointsRequest {
    /**
     * @generated from protobuf field: foodhunt.admin.v1.RestaurantPointFilters filters = 1;
     */
    filters?: RestaurantPointFilters;
    /**
     * @generated from protobuf field: int32 page = 2;
     */
    page: number;
    /**
     * @generated from protobuf field: int32 page_size = 3;
     */
    pageSize: number;
}
/**
 * @generated from protobuf message foodhunt.admin.v1.GetRestaurantPointsResponse
 */
export interface GetRestaurantPointsResponse {
    /**
     * @generated from protobuf field: repeated foodhunt.admin.v1.RestaurantPoint items = 1;
     */
    items: RestaurantPoint[];
}
/**
 * @generated from protobuf message foodhunt.admin.v1.RestaurantFoodFilters
 */
export interface RestaurantFoodFilters {
    /**
     * @generated from protobuf field: foodhunt.admin.v1.RestaurantFoodStatus status = 1;
     */
    status: RestaurantFoodStatus;
    /**
     * @generated from protobuf field: repeated int64 food_ids = 2;
     */
    foodIds: bigint[];
}
/**
 * @generated from protobuf message foodhunt.admin.v1.GetRestaurantFoodsRequest
 */
export interface GetRestaurantFoodsRequest {
    /**
     * @generated from protobuf field: foodhunt.admin.v1.RestaurantFoodFilters filters = 1;
     */
    filters?: RestaurantFoodFilters;
    /**
     * @generated from protobuf field: int32 page = 2;
     */
    page: number;
    /**
     * @generated from protobuf field: int32 page_size = 3;
     */
    pageSize: number;
}
/**
 * @generated from protobuf message foodhunt.admin.v1.RestaurantFood
 */
export interface RestaurantFood {
    /**
     * @generated from protobuf field: int64 id = 1;
     */
    id: bigint;
    /**
     * @generated from protobuf field: int64 restaurant_id = 2;
     */
    restaurantId: bigint;
    /**
     * @generated from protobuf field: string name = 3;
     */
    name: string;
    /**
     * @generated from protobuf field: string url = 5;
     */
    url: string;
    /**
     * @generated from protobuf field: double rating = 6;
     */
    rating: number;
    /**
     * @generated from protobuf field: int64 review_count = 7;
     */
    reviewCount: bigint;
    /**
     * @generated from protobuf field: foodhunt.admin.v1.RestaurantFoodStatus status = 8;
     */
    status: RestaurantFoodStatus;
}
/**
 * @generated from protobuf message foodhunt.admin.v1.GetRestaurantFoodsResponse
 */
export interface GetRestaurantFoodsResponse {
    /**
     * @generated from protobuf field: repeated foodhunt.admin.v1.RestaurantFood items = 1;
     */
    items: RestaurantFood[];
}
/**
 * @generated from protobuf message foodhunt.admin.v1.GetRestaurantFoodByIdRequest
 */
export interface GetRestaurantFoodByIdRequest {
    /**
     * @generated from protobuf field: int64 food_id = 1;
     */
    foodId: bigint;
}
/**
 * @generated from protobuf message foodhunt.admin.v1.GetRestaurantFoodByIdResponse
 */
export interface GetRestaurantFoodByIdResponse {
    /**
     * @generated from protobuf field: foodhunt.admin.v1.RestaurantFood item = 1;
     */
    item?: RestaurantFood;
}
/**
 * @generated from protobuf message foodhunt.admin.v1.RestaurantFoodFeedbackFilters
 */
export interface RestaurantFoodFeedbackFilters {
    /**
     * @generated from protobuf field: foodhunt.admin.v1.RestaurantFoodStatus status = 1;
     */
    status: RestaurantFoodStatus;
    /**
     * @generated from protobuf field: repeated int64 food_ids = 2;
     */
    foodIds: bigint[];
}
/**
 * @generated from protobuf message foodhunt.admin.v1.GetRestaurantFoodFeedbacksRequest
 */
export interface GetRestaurantFoodFeedbacksRequest {
    /**
     * @generated from protobuf field: foodhunt.admin.v1.RestaurantFoodFeedbackStatus status = 1;
     */
    status: RestaurantFoodFeedbackStatus;
    /**
     * @generated from protobuf field: repeated int64 feedback_ids = 2;
     */
    feedbackIds: bigint[];
    /**
     * @generated from protobuf field: int32 page = 3;
     */
    page: number;
    /**
     * @generated from protobuf field: int32 page_size = 4;
     */
    pageSize: number;
}
/**
 * @generated from protobuf message foodhunt.admin.v1.GetRestaurantFoodFeedbacksResponse
 */
export interface GetRestaurantFoodFeedbacksResponse {
    /**
     * @generated from protobuf field: repeated foodhunt.admin.v1.RestaurantFoodFeedback items = 1;
     */
    items: RestaurantFoodFeedback[];
}
/**
 * @generated from protobuf message foodhunt.admin.v1.Profile
 */
export interface Profile {
    /**
     * @generated from protobuf field: int64 id = 1;
     */
    id: bigint;
    /**
     * @generated from protobuf field: string first_name = 2;
     */
    firstName: string;
    /**
     * @generated from protobuf field: string second_name = 3;
     */
    secondName: string;
    /**
     * @generated from protobuf field: string nick_name = 4;
     */
    nickName: string;
    /**
     * @generated from protobuf field: string url = 5;
     */
    url: string;
}
/**
 * @generated from protobuf message foodhunt.admin.v1.RestaurantFoodFeedback
 */
export interface RestaurantFoodFeedback {
    /**
     * @generated from protobuf field: int64 id = 1;
     */
    id: bigint;
    /**
     * @generated from protobuf field: int64 food_id = 2;
     */
    foodId: bigint;
    /**
     * @generated from protobuf field: int64 point_id = 3;
     */
    pointId: bigint;
    /**
     * @generated from protobuf field: string name = 4;
     */
    name: string;
    /**
     * @generated from protobuf field: string description = 5;
     */
    description: string;
    /**
     * @generated from protobuf field: double rating = 6;
     */
    rating: number;
    /**
     * @generated from protobuf field: repeated string urls = 7;
     */
    urls: string[];
    /**
     * @generated from protobuf field: foodhunt.admin.v1.Profile Profile = 8 [json_name = "Profile"];
     */
    profile?: Profile;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated = 9;
     */
    updated?: Timestamp;
    /**
     * @generated from protobuf field: foodhunt.admin.v1.RestaurantFoodFeedbackStatus status = 10;
     */
    status: RestaurantFoodFeedbackStatus;
}
/**
 * @generated from protobuf message foodhunt.admin.v1.GetRestaurantPointByIdRequest
 */
export interface GetRestaurantPointByIdRequest {
    /**
     * @generated from protobuf field: int64 point_id = 1;
     */
    pointId: bigint;
}
/**
 * @generated from protobuf message foodhunt.admin.v1.GetRestaurantPointByIdResponse
 */
export interface GetRestaurantPointByIdResponse {
    /**
     * @generated from protobuf field: foodhunt.admin.v1.RestaurantPoint item = 1;
     */
    item?: RestaurantPoint;
}
/**
 * @generated from protobuf message foodhunt.admin.v1.GetRestaurantFoodFeedbackByIdRequest
 */
export interface GetRestaurantFoodFeedbackByIdRequest {
    /**
     * @generated from protobuf field: int64 feedback_id = 1;
     */
    feedbackId: bigint;
}
/**
 * @generated from protobuf message foodhunt.admin.v1.GetRestaurantFoodFeedbackByIdResponse
 */
export interface GetRestaurantFoodFeedbackByIdResponse {
    /**
     * @generated from protobuf field: foodhunt.admin.v1.RestaurantFoodFeedback item = 1;
     */
    item?: RestaurantFoodFeedback;
}
/**
 * @generated from protobuf message foodhunt.admin.v1.AttachFileToRestaurantRequest
 */
export interface AttachFileToRestaurantRequest {
    /**
     * @generated from protobuf field: int64 restaurant_id = 1;
     */
    restaurantId: bigint;
    /**
     * @generated from protobuf field: int64 file_id = 2;
     */
    fileId: bigint;
}
/**
 * @generated from protobuf message foodhunt.admin.v1.AttachFileToRestaurantResponse
 */
export interface AttachFileToRestaurantResponse {
}
/**
 * @generated from protobuf message foodhunt.admin.v1.UpdateRestaurantRequest
 */
export interface UpdateRestaurantRequest {
    /**
     * @generated from protobuf field: int64 id = 1;
     */
    id: bigint;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: int64 file_id = 4;
     */
    fileId: bigint;
    /**
     * @generated from protobuf field: foodhunt.admin.v1.RestaurantType type = 5;
     */
    type: RestaurantType;
    /**
     * @generated from protobuf field: foodhunt.admin.v1.RestaurantStatus status = 6;
     */
    status: RestaurantStatus;
}
/**
 * @generated from protobuf message foodhunt.admin.v1.UpdateRestaurantResponse
 */
export interface UpdateRestaurantResponse {
}
/**
 * @generated from protobuf message foodhunt.admin.v1.UpdateRestaurantPointRequest
 */
export interface UpdateRestaurantPointRequest {
    /**
     * @generated from protobuf field: int64 id = 1;
     */
    id: bigint;
    /**
     * @generated from protobuf field: int64 restaurant_id = 2;
     */
    restaurantId: bigint;
    /**
     * @generated from protobuf field: string name = 3;
     */
    name: string;
    /**
     * @generated from protobuf field: string url = 4;
     */
    url: string;
    /**
     * @generated from protobuf field: foodhunt.admin.v1.RestaurantType type = 5;
     */
    type: RestaurantType;
    /**
     * @generated from protobuf field: double latitude = 6;
     */
    latitude: number;
    /**
     * @generated from protobuf field: double longitude = 7;
     */
    longitude: number;
    /**
     * @generated from protobuf field: double rating = 8;
     */
    rating: number;
    /**
     * @generated from protobuf field: int64 review_count = 9;
     */
    reviewCount: bigint;
    /**
     * @generated from protobuf field: int64 distance = 10;
     */
    distance: bigint;
    /**
     * @generated from protobuf field: foodhunt.admin.v1.RestaurantPointStatus status = 11;
     */
    status: RestaurantPointStatus;
}
/**
 * @generated from protobuf message foodhunt.admin.v1.UpdateRestaurantPointResponse
 */
export interface UpdateRestaurantPointResponse {
}
/**
 * @generated from protobuf message foodhunt.admin.v1.UpdateRestaurantFoodRequest
 */
export interface UpdateRestaurantFoodRequest {
    /**
     * @generated from protobuf field: int64 id = 1;
     */
    id: bigint;
    /**
     * @generated from protobuf field: int64 restaurant_id = 2;
     */
    restaurantId: bigint;
    /**
     * @generated from protobuf field: string name = 3;
     */
    name: string;
    /**
     * @generated from protobuf field: string url = 5;
     */
    url: string;
    /**
     * @generated from protobuf field: double rating = 6;
     */
    rating: number;
    /**
     * @generated from protobuf field: int64 review_count = 7;
     */
    reviewCount: bigint;
    /**
     * @generated from protobuf field: foodhunt.admin.v1.RestaurantFoodStatus status = 8;
     */
    status: RestaurantFoodStatus;
}
/**
 * @generated from protobuf message foodhunt.admin.v1.UpdateRestaurantFoodResponse
 */
export interface UpdateRestaurantFoodResponse {
}
/**
 * @generated from protobuf message foodhunt.admin.v1.GetRestaurantByIdRequest
 */
export interface GetRestaurantByIdRequest {
    /**
     * @generated from protobuf field: int64 restaurant_id = 1;
     */
    restaurantId: bigint;
}
/**
 * @generated from protobuf message foodhunt.admin.v1.GetRestaurantByIdResponse
 */
export interface GetRestaurantByIdResponse {
    /**
     * @generated from protobuf field: foodhunt.admin.v1.RestaurantSearch item = 1;
     */
    item?: RestaurantSearch;
}
/**
 * @generated from protobuf enum foodhunt.admin.v1.RestaurantType
 */
export enum RestaurantType {
    /**
     * @generated from protobuf enum value: REST_TYPE_INVALID = 0;
     */
    REST_TYPE_INVALID = 0,
    /**
     * @generated from protobuf enum value: REST_TYPE_RESTAURANT = 1;
     */
    REST_TYPE_RESTAURANT = 1,
    /**
     * @generated from protobuf enum value: REST_TYPE_CAFE = 2;
     */
    REST_TYPE_CAFE = 2,
    /**
     * @generated from protobuf enum value: REST_TYPE_BAR = 3;
     */
    REST_TYPE_BAR = 3,
    /**
     * @generated from protobuf enum value: REST_TYPE_BAKERY = 4;
     */
    REST_TYPE_BAKERY = 4,
    /**
     * @generated from protobuf enum value: REST_TYPE_DINING_ROOM = 5;
     */
    REST_TYPE_DINING_ROOM = 5,
    /**
     * @generated from protobuf enum value: REST_TYPE_FAST_FOOD = 6;
     */
    REST_TYPE_FAST_FOOD = 6,
    /**
     * @generated from protobuf enum value: REST_TYPE_PIZZERIA = 7;
     */
    REST_TYPE_PIZZERIA = 7
}
/**
 * @generated from protobuf enum foodhunt.admin.v1.RestaurantStatus
 */
export enum RestaurantStatus {
    /**
     * @generated from protobuf enum value: REST_STATUS_CREATED = 0;
     */
    REST_STATUS_CREATED = 0,
    /**
     * @generated from protobuf enum value: REST_STATUS_ACCEPTED = 1;
     */
    REST_STATUS_ACCEPTED = 1,
    /**
     * @generated from protobuf enum value: REST_STATUS_RELEASED = 2;
     */
    REST_STATUS_RELEASED = 2
}
/**
 * @generated from protobuf enum foodhunt.admin.v1.RestaurantPointStatus
 */
export enum RestaurantPointStatus {
    /**
     * @generated from protobuf enum value: REST_POINT_STATUS_CREATED = 0;
     */
    REST_POINT_STATUS_CREATED = 0,
    /**
     * @generated from protobuf enum value: REST_POINT_STATUS_ACCEPTED = 1;
     */
    REST_POINT_STATUS_ACCEPTED = 1,
    /**
     * @generated from protobuf enum value: REST_POINT_STATUS_RELEASED = 2;
     */
    REST_POINT_STATUS_RELEASED = 2
}
/**
 * @generated from protobuf enum foodhunt.admin.v1.RestaurantFoodStatus
 */
export enum RestaurantFoodStatus {
    /**
     * @generated from protobuf enum value: REST_FOOD_STATUS_CREATED = 0;
     */
    REST_FOOD_STATUS_CREATED = 0,
    /**
     * @generated from protobuf enum value: REST_FOOD_STATUS_ACCEPTED = 1;
     */
    REST_FOOD_STATUS_ACCEPTED = 1,
    /**
     * @generated from protobuf enum value: REST_FOOD_STATUS_RELEASED = 2;
     */
    REST_FOOD_STATUS_RELEASED = 2
}
/**
 * @generated from protobuf enum foodhunt.admin.v1.RestaurantFoodFeedbackStatus
 */
export enum RestaurantFoodFeedbackStatus {
    /**
     * @generated from protobuf enum value: REST_FOOD_FEEDBACK_STATUS_CREATED = 0;
     */
    REST_FOOD_FEEDBACK_STATUS_CREATED = 0,
    /**
     * @generated from protobuf enum value: REST_FOOD_FEEDBACK_STATUS_ACCEPTED = 1;
     */
    REST_FOOD_FEEDBACK_STATUS_ACCEPTED = 1,
    /**
     * @generated from protobuf enum value: REST_FOOD_FEEDBACK_STATUS_RELEASED = 2;
     */
    REST_FOOD_FEEDBACK_STATUS_RELEASED = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class AcceptRestaurantRequest$Type extends MessageType<AcceptRestaurantRequest> {
    constructor() {
        super("foodhunt.admin.v1.AcceptRestaurantRequest", [
            { no: 1, name: "restaurant_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<AcceptRestaurantRequest>): AcceptRestaurantRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.restaurantId = 0n;
        if (value !== undefined)
            reflectionMergePartial<AcceptRestaurantRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AcceptRestaurantRequest): AcceptRestaurantRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 restaurant_id */ 1:
                    message.restaurantId = reader.int64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AcceptRestaurantRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 restaurant_id = 1; */
        if (message.restaurantId !== 0n)
            writer.tag(1, WireType.Varint).int64(message.restaurantId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message foodhunt.admin.v1.AcceptRestaurantRequest
 */
export const AcceptRestaurantRequest = new AcceptRestaurantRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AcceptRestaurantResponse$Type extends MessageType<AcceptRestaurantResponse> {
    constructor() {
        super("foodhunt.admin.v1.AcceptRestaurantResponse", []);
    }
    create(value?: PartialMessage<AcceptRestaurantResponse>): AcceptRestaurantResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<AcceptRestaurantResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AcceptRestaurantResponse): AcceptRestaurantResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AcceptRestaurantResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message foodhunt.admin.v1.AcceptRestaurantResponse
 */
export const AcceptRestaurantResponse = new AcceptRestaurantResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AcceptRestaurantPointRequest$Type extends MessageType<AcceptRestaurantPointRequest> {
    constructor() {
        super("foodhunt.admin.v1.AcceptRestaurantPointRequest", [
            { no: 1, name: "point_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<AcceptRestaurantPointRequest>): AcceptRestaurantPointRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.pointId = 0n;
        if (value !== undefined)
            reflectionMergePartial<AcceptRestaurantPointRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AcceptRestaurantPointRequest): AcceptRestaurantPointRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 point_id */ 1:
                    message.pointId = reader.int64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AcceptRestaurantPointRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 point_id = 1; */
        if (message.pointId !== 0n)
            writer.tag(1, WireType.Varint).int64(message.pointId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message foodhunt.admin.v1.AcceptRestaurantPointRequest
 */
export const AcceptRestaurantPointRequest = new AcceptRestaurantPointRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AcceptRestaurantPointResponse$Type extends MessageType<AcceptRestaurantPointResponse> {
    constructor() {
        super("foodhunt.admin.v1.AcceptRestaurantPointResponse", []);
    }
    create(value?: PartialMessage<AcceptRestaurantPointResponse>): AcceptRestaurantPointResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<AcceptRestaurantPointResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AcceptRestaurantPointResponse): AcceptRestaurantPointResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AcceptRestaurantPointResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message foodhunt.admin.v1.AcceptRestaurantPointResponse
 */
export const AcceptRestaurantPointResponse = new AcceptRestaurantPointResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AcceptRestaurantFoodRequest$Type extends MessageType<AcceptRestaurantFoodRequest> {
    constructor() {
        super("foodhunt.admin.v1.AcceptRestaurantFoodRequest", [
            { no: 1, name: "food_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<AcceptRestaurantFoodRequest>): AcceptRestaurantFoodRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.foodId = 0n;
        if (value !== undefined)
            reflectionMergePartial<AcceptRestaurantFoodRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AcceptRestaurantFoodRequest): AcceptRestaurantFoodRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 food_id */ 1:
                    message.foodId = reader.int64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AcceptRestaurantFoodRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 food_id = 1; */
        if (message.foodId !== 0n)
            writer.tag(1, WireType.Varint).int64(message.foodId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message foodhunt.admin.v1.AcceptRestaurantFoodRequest
 */
export const AcceptRestaurantFoodRequest = new AcceptRestaurantFoodRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AcceptRestaurantFoodResponse$Type extends MessageType<AcceptRestaurantFoodResponse> {
    constructor() {
        super("foodhunt.admin.v1.AcceptRestaurantFoodResponse", []);
    }
    create(value?: PartialMessage<AcceptRestaurantFoodResponse>): AcceptRestaurantFoodResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<AcceptRestaurantFoodResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AcceptRestaurantFoodResponse): AcceptRestaurantFoodResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AcceptRestaurantFoodResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message foodhunt.admin.v1.AcceptRestaurantFoodResponse
 */
export const AcceptRestaurantFoodResponse = new AcceptRestaurantFoodResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AcceptRestaurantFoodFeedbackdRequest$Type extends MessageType<AcceptRestaurantFoodFeedbackdRequest> {
    constructor() {
        super("foodhunt.admin.v1.AcceptRestaurantFoodFeedbackdRequest", [
            { no: 1, name: "feedback_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<AcceptRestaurantFoodFeedbackdRequest>): AcceptRestaurantFoodFeedbackdRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.feedbackId = 0n;
        if (value !== undefined)
            reflectionMergePartial<AcceptRestaurantFoodFeedbackdRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AcceptRestaurantFoodFeedbackdRequest): AcceptRestaurantFoodFeedbackdRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 feedback_id */ 1:
                    message.feedbackId = reader.int64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AcceptRestaurantFoodFeedbackdRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 feedback_id = 1; */
        if (message.feedbackId !== 0n)
            writer.tag(1, WireType.Varint).int64(message.feedbackId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message foodhunt.admin.v1.AcceptRestaurantFoodFeedbackdRequest
 */
export const AcceptRestaurantFoodFeedbackdRequest = new AcceptRestaurantFoodFeedbackdRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AcceptRestaurantFoodFeedbackResponse$Type extends MessageType<AcceptRestaurantFoodFeedbackResponse> {
    constructor() {
        super("foodhunt.admin.v1.AcceptRestaurantFoodFeedbackResponse", []);
    }
    create(value?: PartialMessage<AcceptRestaurantFoodFeedbackResponse>): AcceptRestaurantFoodFeedbackResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<AcceptRestaurantFoodFeedbackResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AcceptRestaurantFoodFeedbackResponse): AcceptRestaurantFoodFeedbackResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AcceptRestaurantFoodFeedbackResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message foodhunt.admin.v1.AcceptRestaurantFoodFeedbackResponse
 */
export const AcceptRestaurantFoodFeedbackResponse = new AcceptRestaurantFoodFeedbackResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RestaurantFilters$Type extends MessageType<RestaurantFilters> {
    constructor() {
        super("foodhunt.admin.v1.RestaurantFilters", [
            { no: 1, name: "status", kind: "enum", T: () => ["foodhunt.admin.v1.RestaurantStatus", RestaurantStatus] },
            { no: 2, name: "type", kind: "enum", T: () => ["foodhunt.admin.v1.RestaurantType", RestaurantType] },
            { no: 3, name: "restaurant_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 4, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RestaurantFilters>): RestaurantFilters {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.status = 0;
        message.type = 0;
        message.restaurantIds = [];
        message.name = "";
        if (value !== undefined)
            reflectionMergePartial<RestaurantFilters>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RestaurantFilters): RestaurantFilters {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* foodhunt.admin.v1.RestaurantStatus status */ 1:
                    message.status = reader.int32();
                    break;
                case /* foodhunt.admin.v1.RestaurantType type */ 2:
                    message.type = reader.int32();
                    break;
                case /* repeated int64 restaurant_ids */ 3:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.restaurantIds.push(reader.int64().toBigInt());
                    else
                        message.restaurantIds.push(reader.int64().toBigInt());
                    break;
                case /* string name */ 4:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RestaurantFilters, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* foodhunt.admin.v1.RestaurantStatus status = 1; */
        if (message.status !== 0)
            writer.tag(1, WireType.Varint).int32(message.status);
        /* foodhunt.admin.v1.RestaurantType type = 2; */
        if (message.type !== 0)
            writer.tag(2, WireType.Varint).int32(message.type);
        /* repeated int64 restaurant_ids = 3; */
        if (message.restaurantIds.length) {
            writer.tag(3, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.restaurantIds.length; i++)
                writer.int64(message.restaurantIds[i]);
            writer.join();
        }
        /* string name = 4; */
        if (message.name !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message foodhunt.admin.v1.RestaurantFilters
 */
export const RestaurantFilters = new RestaurantFilters$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRestaurantsRequest$Type extends MessageType<GetRestaurantsRequest> {
    constructor() {
        super("foodhunt.admin.v1.GetRestaurantsRequest", [
            { no: 1, name: "filters", kind: "message", T: () => RestaurantFilters },
            { no: 2, name: "page", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetRestaurantsRequest>): GetRestaurantsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.pageSize = 0;
        if (value !== undefined)
            reflectionMergePartial<GetRestaurantsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRestaurantsRequest): GetRestaurantsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* foodhunt.admin.v1.RestaurantFilters filters */ 1:
                    message.filters = RestaurantFilters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* int32 page */ 2:
                    message.page = reader.int32();
                    break;
                case /* int32 page_size */ 3:
                    message.pageSize = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRestaurantsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* foodhunt.admin.v1.RestaurantFilters filters = 1; */
        if (message.filters)
            RestaurantFilters.internalBinaryWrite(message.filters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 page = 2; */
        if (message.page !== 0)
            writer.tag(2, WireType.Varint).int32(message.page);
        /* int32 page_size = 3; */
        if (message.pageSize !== 0)
            writer.tag(3, WireType.Varint).int32(message.pageSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message foodhunt.admin.v1.GetRestaurantsRequest
 */
export const GetRestaurantsRequest = new GetRestaurantsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RestaurantSearch$Type extends MessageType<RestaurantSearch> {
    constructor() {
        super("foodhunt.admin.v1.RestaurantSearch", [
            { no: 1, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "type", kind: "enum", T: () => ["foodhunt.admin.v1.RestaurantType", RestaurantType] },
            { no: 5, name: "status", kind: "enum", T: () => ["foodhunt.admin.v1.RestaurantStatus", RestaurantStatus] }
        ]);
    }
    create(value?: PartialMessage<RestaurantSearch>): RestaurantSearch {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0n;
        message.name = "";
        message.url = "";
        message.type = 0;
        message.status = 0;
        if (value !== undefined)
            reflectionMergePartial<RestaurantSearch>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RestaurantSearch): RestaurantSearch {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 id */ 1:
                    message.id = reader.int64().toBigInt();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string url */ 3:
                    message.url = reader.string();
                    break;
                case /* foodhunt.admin.v1.RestaurantType type */ 4:
                    message.type = reader.int32();
                    break;
                case /* foodhunt.admin.v1.RestaurantStatus status */ 5:
                    message.status = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RestaurantSearch, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 id = 1; */
        if (message.id !== 0n)
            writer.tag(1, WireType.Varint).int64(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string url = 3; */
        if (message.url !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.url);
        /* foodhunt.admin.v1.RestaurantType type = 4; */
        if (message.type !== 0)
            writer.tag(4, WireType.Varint).int32(message.type);
        /* foodhunt.admin.v1.RestaurantStatus status = 5; */
        if (message.status !== 0)
            writer.tag(5, WireType.Varint).int32(message.status);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message foodhunt.admin.v1.RestaurantSearch
 */
export const RestaurantSearch = new RestaurantSearch$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRestaurantsResponse$Type extends MessageType<GetRestaurantsResponse> {
    constructor() {
        super("foodhunt.admin.v1.GetRestaurantsResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RestaurantSearch }
        ]);
    }
    create(value?: PartialMessage<GetRestaurantsResponse>): GetRestaurantsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.items = [];
        if (value !== undefined)
            reflectionMergePartial<GetRestaurantsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRestaurantsResponse): GetRestaurantsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated foodhunt.admin.v1.RestaurantSearch items */ 1:
                    message.items.push(RestaurantSearch.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRestaurantsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated foodhunt.admin.v1.RestaurantSearch items = 1; */
        for (let i = 0; i < message.items.length; i++)
            RestaurantSearch.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message foodhunt.admin.v1.GetRestaurantsResponse
 */
export const GetRestaurantsResponse = new GetRestaurantsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RestaurantPoint$Type extends MessageType<RestaurantPoint> {
    constructor() {
        super("foodhunt.admin.v1.RestaurantPoint", [
            { no: 1, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "restaurant_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "type", kind: "enum", T: () => ["foodhunt.admin.v1.RestaurantType", RestaurantType] },
            { no: 6, name: "latitude", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 7, name: "longitude", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 8, name: "rating", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 9, name: "review_count", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 10, name: "distance", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 11, name: "status", kind: "enum", T: () => ["foodhunt.admin.v1.RestaurantPointStatus", RestaurantPointStatus] }
        ]);
    }
    create(value?: PartialMessage<RestaurantPoint>): RestaurantPoint {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0n;
        message.restaurantId = 0n;
        message.name = "";
        message.url = "";
        message.type = 0;
        message.latitude = 0;
        message.longitude = 0;
        message.rating = 0;
        message.reviewCount = 0n;
        message.distance = 0n;
        message.status = 0;
        if (value !== undefined)
            reflectionMergePartial<RestaurantPoint>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RestaurantPoint): RestaurantPoint {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 id */ 1:
                    message.id = reader.int64().toBigInt();
                    break;
                case /* int64 restaurant_id */ 2:
                    message.restaurantId = reader.int64().toBigInt();
                    break;
                case /* string name */ 3:
                    message.name = reader.string();
                    break;
                case /* string url */ 4:
                    message.url = reader.string();
                    break;
                case /* foodhunt.admin.v1.RestaurantType type */ 5:
                    message.type = reader.int32();
                    break;
                case /* double latitude */ 6:
                    message.latitude = reader.double();
                    break;
                case /* double longitude */ 7:
                    message.longitude = reader.double();
                    break;
                case /* double rating */ 8:
                    message.rating = reader.double();
                    break;
                case /* int64 review_count */ 9:
                    message.reviewCount = reader.int64().toBigInt();
                    break;
                case /* int64 distance */ 10:
                    message.distance = reader.int64().toBigInt();
                    break;
                case /* foodhunt.admin.v1.RestaurantPointStatus status */ 11:
                    message.status = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RestaurantPoint, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 id = 1; */
        if (message.id !== 0n)
            writer.tag(1, WireType.Varint).int64(message.id);
        /* int64 restaurant_id = 2; */
        if (message.restaurantId !== 0n)
            writer.tag(2, WireType.Varint).int64(message.restaurantId);
        /* string name = 3; */
        if (message.name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.name);
        /* string url = 4; */
        if (message.url !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.url);
        /* foodhunt.admin.v1.RestaurantType type = 5; */
        if (message.type !== 0)
            writer.tag(5, WireType.Varint).int32(message.type);
        /* double latitude = 6; */
        if (message.latitude !== 0)
            writer.tag(6, WireType.Bit64).double(message.latitude);
        /* double longitude = 7; */
        if (message.longitude !== 0)
            writer.tag(7, WireType.Bit64).double(message.longitude);
        /* double rating = 8; */
        if (message.rating !== 0)
            writer.tag(8, WireType.Bit64).double(message.rating);
        /* int64 review_count = 9; */
        if (message.reviewCount !== 0n)
            writer.tag(9, WireType.Varint).int64(message.reviewCount);
        /* int64 distance = 10; */
        if (message.distance !== 0n)
            writer.tag(10, WireType.Varint).int64(message.distance);
        /* foodhunt.admin.v1.RestaurantPointStatus status = 11; */
        if (message.status !== 0)
            writer.tag(11, WireType.Varint).int32(message.status);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message foodhunt.admin.v1.RestaurantPoint
 */
export const RestaurantPoint = new RestaurantPoint$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RestaurantPointFilters$Type extends MessageType<RestaurantPointFilters> {
    constructor() {
        super("foodhunt.admin.v1.RestaurantPointFilters", [
            { no: 1, name: "status", kind: "enum", T: () => ["foodhunt.admin.v1.RestaurantPointStatus", RestaurantPointStatus] },
            { no: 2, name: "point_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<RestaurantPointFilters>): RestaurantPointFilters {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.status = 0;
        message.pointIds = [];
        if (value !== undefined)
            reflectionMergePartial<RestaurantPointFilters>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RestaurantPointFilters): RestaurantPointFilters {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* foodhunt.admin.v1.RestaurantPointStatus status */ 1:
                    message.status = reader.int32();
                    break;
                case /* repeated int64 point_ids */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.pointIds.push(reader.int64().toBigInt());
                    else
                        message.pointIds.push(reader.int64().toBigInt());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RestaurantPointFilters, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* foodhunt.admin.v1.RestaurantPointStatus status = 1; */
        if (message.status !== 0)
            writer.tag(1, WireType.Varint).int32(message.status);
        /* repeated int64 point_ids = 2; */
        if (message.pointIds.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.pointIds.length; i++)
                writer.int64(message.pointIds[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message foodhunt.admin.v1.RestaurantPointFilters
 */
export const RestaurantPointFilters = new RestaurantPointFilters$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRestaurantPointsRequest$Type extends MessageType<GetRestaurantPointsRequest> {
    constructor() {
        super("foodhunt.admin.v1.GetRestaurantPointsRequest", [
            { no: 1, name: "filters", kind: "message", T: () => RestaurantPointFilters },
            { no: 2, name: "page", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetRestaurantPointsRequest>): GetRestaurantPointsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.pageSize = 0;
        if (value !== undefined)
            reflectionMergePartial<GetRestaurantPointsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRestaurantPointsRequest): GetRestaurantPointsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* foodhunt.admin.v1.RestaurantPointFilters filters */ 1:
                    message.filters = RestaurantPointFilters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* int32 page */ 2:
                    message.page = reader.int32();
                    break;
                case /* int32 page_size */ 3:
                    message.pageSize = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRestaurantPointsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* foodhunt.admin.v1.RestaurantPointFilters filters = 1; */
        if (message.filters)
            RestaurantPointFilters.internalBinaryWrite(message.filters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 page = 2; */
        if (message.page !== 0)
            writer.tag(2, WireType.Varint).int32(message.page);
        /* int32 page_size = 3; */
        if (message.pageSize !== 0)
            writer.tag(3, WireType.Varint).int32(message.pageSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message foodhunt.admin.v1.GetRestaurantPointsRequest
 */
export const GetRestaurantPointsRequest = new GetRestaurantPointsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRestaurantPointsResponse$Type extends MessageType<GetRestaurantPointsResponse> {
    constructor() {
        super("foodhunt.admin.v1.GetRestaurantPointsResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RestaurantPoint }
        ]);
    }
    create(value?: PartialMessage<GetRestaurantPointsResponse>): GetRestaurantPointsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.items = [];
        if (value !== undefined)
            reflectionMergePartial<GetRestaurantPointsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRestaurantPointsResponse): GetRestaurantPointsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated foodhunt.admin.v1.RestaurantPoint items */ 1:
                    message.items.push(RestaurantPoint.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRestaurantPointsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated foodhunt.admin.v1.RestaurantPoint items = 1; */
        for (let i = 0; i < message.items.length; i++)
            RestaurantPoint.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message foodhunt.admin.v1.GetRestaurantPointsResponse
 */
export const GetRestaurantPointsResponse = new GetRestaurantPointsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RestaurantFoodFilters$Type extends MessageType<RestaurantFoodFilters> {
    constructor() {
        super("foodhunt.admin.v1.RestaurantFoodFilters", [
            { no: 1, name: "status", kind: "enum", T: () => ["foodhunt.admin.v1.RestaurantFoodStatus", RestaurantFoodStatus] },
            { no: 2, name: "food_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<RestaurantFoodFilters>): RestaurantFoodFilters {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.status = 0;
        message.foodIds = [];
        if (value !== undefined)
            reflectionMergePartial<RestaurantFoodFilters>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RestaurantFoodFilters): RestaurantFoodFilters {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* foodhunt.admin.v1.RestaurantFoodStatus status */ 1:
                    message.status = reader.int32();
                    break;
                case /* repeated int64 food_ids */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.foodIds.push(reader.int64().toBigInt());
                    else
                        message.foodIds.push(reader.int64().toBigInt());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RestaurantFoodFilters, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* foodhunt.admin.v1.RestaurantFoodStatus status = 1; */
        if (message.status !== 0)
            writer.tag(1, WireType.Varint).int32(message.status);
        /* repeated int64 food_ids = 2; */
        if (message.foodIds.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.foodIds.length; i++)
                writer.int64(message.foodIds[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message foodhunt.admin.v1.RestaurantFoodFilters
 */
export const RestaurantFoodFilters = new RestaurantFoodFilters$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRestaurantFoodsRequest$Type extends MessageType<GetRestaurantFoodsRequest> {
    constructor() {
        super("foodhunt.admin.v1.GetRestaurantFoodsRequest", [
            { no: 1, name: "filters", kind: "message", T: () => RestaurantFoodFilters },
            { no: 2, name: "page", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetRestaurantFoodsRequest>): GetRestaurantFoodsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.pageSize = 0;
        if (value !== undefined)
            reflectionMergePartial<GetRestaurantFoodsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRestaurantFoodsRequest): GetRestaurantFoodsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* foodhunt.admin.v1.RestaurantFoodFilters filters */ 1:
                    message.filters = RestaurantFoodFilters.internalBinaryRead(reader, reader.uint32(), options, message.filters);
                    break;
                case /* int32 page */ 2:
                    message.page = reader.int32();
                    break;
                case /* int32 page_size */ 3:
                    message.pageSize = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRestaurantFoodsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* foodhunt.admin.v1.RestaurantFoodFilters filters = 1; */
        if (message.filters)
            RestaurantFoodFilters.internalBinaryWrite(message.filters, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 page = 2; */
        if (message.page !== 0)
            writer.tag(2, WireType.Varint).int32(message.page);
        /* int32 page_size = 3; */
        if (message.pageSize !== 0)
            writer.tag(3, WireType.Varint).int32(message.pageSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message foodhunt.admin.v1.GetRestaurantFoodsRequest
 */
export const GetRestaurantFoodsRequest = new GetRestaurantFoodsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RestaurantFood$Type extends MessageType<RestaurantFood> {
    constructor() {
        super("foodhunt.admin.v1.RestaurantFood", [
            { no: 1, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "restaurant_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "rating", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 7, name: "review_count", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 8, name: "status", kind: "enum", T: () => ["foodhunt.admin.v1.RestaurantFoodStatus", RestaurantFoodStatus] }
        ]);
    }
    create(value?: PartialMessage<RestaurantFood>): RestaurantFood {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0n;
        message.restaurantId = 0n;
        message.name = "";
        message.url = "";
        message.rating = 0;
        message.reviewCount = 0n;
        message.status = 0;
        if (value !== undefined)
            reflectionMergePartial<RestaurantFood>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RestaurantFood): RestaurantFood {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 id */ 1:
                    message.id = reader.int64().toBigInt();
                    break;
                case /* int64 restaurant_id */ 2:
                    message.restaurantId = reader.int64().toBigInt();
                    break;
                case /* string name */ 3:
                    message.name = reader.string();
                    break;
                case /* string url */ 5:
                    message.url = reader.string();
                    break;
                case /* double rating */ 6:
                    message.rating = reader.double();
                    break;
                case /* int64 review_count */ 7:
                    message.reviewCount = reader.int64().toBigInt();
                    break;
                case /* foodhunt.admin.v1.RestaurantFoodStatus status */ 8:
                    message.status = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RestaurantFood, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 id = 1; */
        if (message.id !== 0n)
            writer.tag(1, WireType.Varint).int64(message.id);
        /* int64 restaurant_id = 2; */
        if (message.restaurantId !== 0n)
            writer.tag(2, WireType.Varint).int64(message.restaurantId);
        /* string name = 3; */
        if (message.name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.name);
        /* string url = 5; */
        if (message.url !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.url);
        /* double rating = 6; */
        if (message.rating !== 0)
            writer.tag(6, WireType.Bit64).double(message.rating);
        /* int64 review_count = 7; */
        if (message.reviewCount !== 0n)
            writer.tag(7, WireType.Varint).int64(message.reviewCount);
        /* foodhunt.admin.v1.RestaurantFoodStatus status = 8; */
        if (message.status !== 0)
            writer.tag(8, WireType.Varint).int32(message.status);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message foodhunt.admin.v1.RestaurantFood
 */
export const RestaurantFood = new RestaurantFood$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRestaurantFoodsResponse$Type extends MessageType<GetRestaurantFoodsResponse> {
    constructor() {
        super("foodhunt.admin.v1.GetRestaurantFoodsResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RestaurantFood }
        ]);
    }
    create(value?: PartialMessage<GetRestaurantFoodsResponse>): GetRestaurantFoodsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.items = [];
        if (value !== undefined)
            reflectionMergePartial<GetRestaurantFoodsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRestaurantFoodsResponse): GetRestaurantFoodsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated foodhunt.admin.v1.RestaurantFood items */ 1:
                    message.items.push(RestaurantFood.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRestaurantFoodsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated foodhunt.admin.v1.RestaurantFood items = 1; */
        for (let i = 0; i < message.items.length; i++)
            RestaurantFood.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message foodhunt.admin.v1.GetRestaurantFoodsResponse
 */
export const GetRestaurantFoodsResponse = new GetRestaurantFoodsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRestaurantFoodByIdRequest$Type extends MessageType<GetRestaurantFoodByIdRequest> {
    constructor() {
        super("foodhunt.admin.v1.GetRestaurantFoodByIdRequest", [
            { no: 1, name: "food_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<GetRestaurantFoodByIdRequest>): GetRestaurantFoodByIdRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.foodId = 0n;
        if (value !== undefined)
            reflectionMergePartial<GetRestaurantFoodByIdRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRestaurantFoodByIdRequest): GetRestaurantFoodByIdRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 food_id */ 1:
                    message.foodId = reader.int64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRestaurantFoodByIdRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 food_id = 1; */
        if (message.foodId !== 0n)
            writer.tag(1, WireType.Varint).int64(message.foodId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message foodhunt.admin.v1.GetRestaurantFoodByIdRequest
 */
export const GetRestaurantFoodByIdRequest = new GetRestaurantFoodByIdRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRestaurantFoodByIdResponse$Type extends MessageType<GetRestaurantFoodByIdResponse> {
    constructor() {
        super("foodhunt.admin.v1.GetRestaurantFoodByIdResponse", [
            { no: 1, name: "item", kind: "message", T: () => RestaurantFood }
        ]);
    }
    create(value?: PartialMessage<GetRestaurantFoodByIdResponse>): GetRestaurantFoodByIdResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetRestaurantFoodByIdResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRestaurantFoodByIdResponse): GetRestaurantFoodByIdResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* foodhunt.admin.v1.RestaurantFood item */ 1:
                    message.item = RestaurantFood.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRestaurantFoodByIdResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* foodhunt.admin.v1.RestaurantFood item = 1; */
        if (message.item)
            RestaurantFood.internalBinaryWrite(message.item, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message foodhunt.admin.v1.GetRestaurantFoodByIdResponse
 */
export const GetRestaurantFoodByIdResponse = new GetRestaurantFoodByIdResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RestaurantFoodFeedbackFilters$Type extends MessageType<RestaurantFoodFeedbackFilters> {
    constructor() {
        super("foodhunt.admin.v1.RestaurantFoodFeedbackFilters", [
            { no: 1, name: "status", kind: "enum", T: () => ["foodhunt.admin.v1.RestaurantFoodStatus", RestaurantFoodStatus] },
            { no: 2, name: "food_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<RestaurantFoodFeedbackFilters>): RestaurantFoodFeedbackFilters {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.status = 0;
        message.foodIds = [];
        if (value !== undefined)
            reflectionMergePartial<RestaurantFoodFeedbackFilters>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RestaurantFoodFeedbackFilters): RestaurantFoodFeedbackFilters {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* foodhunt.admin.v1.RestaurantFoodStatus status */ 1:
                    message.status = reader.int32();
                    break;
                case /* repeated int64 food_ids */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.foodIds.push(reader.int64().toBigInt());
                    else
                        message.foodIds.push(reader.int64().toBigInt());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RestaurantFoodFeedbackFilters, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* foodhunt.admin.v1.RestaurantFoodStatus status = 1; */
        if (message.status !== 0)
            writer.tag(1, WireType.Varint).int32(message.status);
        /* repeated int64 food_ids = 2; */
        if (message.foodIds.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.foodIds.length; i++)
                writer.int64(message.foodIds[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message foodhunt.admin.v1.RestaurantFoodFeedbackFilters
 */
export const RestaurantFoodFeedbackFilters = new RestaurantFoodFeedbackFilters$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRestaurantFoodFeedbacksRequest$Type extends MessageType<GetRestaurantFoodFeedbacksRequest> {
    constructor() {
        super("foodhunt.admin.v1.GetRestaurantFoodFeedbacksRequest", [
            { no: 1, name: "status", kind: "enum", T: () => ["foodhunt.admin.v1.RestaurantFoodFeedbackStatus", RestaurantFoodFeedbackStatus] },
            { no: 2, name: "feedback_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 3, name: "page", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetRestaurantFoodFeedbacksRequest>): GetRestaurantFoodFeedbacksRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.status = 0;
        message.feedbackIds = [];
        message.page = 0;
        message.pageSize = 0;
        if (value !== undefined)
            reflectionMergePartial<GetRestaurantFoodFeedbacksRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRestaurantFoodFeedbacksRequest): GetRestaurantFoodFeedbacksRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* foodhunt.admin.v1.RestaurantFoodFeedbackStatus status */ 1:
                    message.status = reader.int32();
                    break;
                case /* repeated int64 feedback_ids */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.feedbackIds.push(reader.int64().toBigInt());
                    else
                        message.feedbackIds.push(reader.int64().toBigInt());
                    break;
                case /* int32 page */ 3:
                    message.page = reader.int32();
                    break;
                case /* int32 page_size */ 4:
                    message.pageSize = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRestaurantFoodFeedbacksRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* foodhunt.admin.v1.RestaurantFoodFeedbackStatus status = 1; */
        if (message.status !== 0)
            writer.tag(1, WireType.Varint).int32(message.status);
        /* repeated int64 feedback_ids = 2; */
        if (message.feedbackIds.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.feedbackIds.length; i++)
                writer.int64(message.feedbackIds[i]);
            writer.join();
        }
        /* int32 page = 3; */
        if (message.page !== 0)
            writer.tag(3, WireType.Varint).int32(message.page);
        /* int32 page_size = 4; */
        if (message.pageSize !== 0)
            writer.tag(4, WireType.Varint).int32(message.pageSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message foodhunt.admin.v1.GetRestaurantFoodFeedbacksRequest
 */
export const GetRestaurantFoodFeedbacksRequest = new GetRestaurantFoodFeedbacksRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRestaurantFoodFeedbacksResponse$Type extends MessageType<GetRestaurantFoodFeedbacksResponse> {
    constructor() {
        super("foodhunt.admin.v1.GetRestaurantFoodFeedbacksResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RestaurantFoodFeedback }
        ]);
    }
    create(value?: PartialMessage<GetRestaurantFoodFeedbacksResponse>): GetRestaurantFoodFeedbacksResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.items = [];
        if (value !== undefined)
            reflectionMergePartial<GetRestaurantFoodFeedbacksResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRestaurantFoodFeedbacksResponse): GetRestaurantFoodFeedbacksResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated foodhunt.admin.v1.RestaurantFoodFeedback items */ 1:
                    message.items.push(RestaurantFoodFeedback.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRestaurantFoodFeedbacksResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated foodhunt.admin.v1.RestaurantFoodFeedback items = 1; */
        for (let i = 0; i < message.items.length; i++)
            RestaurantFoodFeedback.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message foodhunt.admin.v1.GetRestaurantFoodFeedbacksResponse
 */
export const GetRestaurantFoodFeedbacksResponse = new GetRestaurantFoodFeedbacksResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Profile$Type extends MessageType<Profile> {
    constructor() {
        super("foodhunt.admin.v1.Profile", [
            { no: 1, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "first_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "second_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "nick_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Profile>): Profile {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0n;
        message.firstName = "";
        message.secondName = "";
        message.nickName = "";
        message.url = "";
        if (value !== undefined)
            reflectionMergePartial<Profile>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Profile): Profile {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 id */ 1:
                    message.id = reader.int64().toBigInt();
                    break;
                case /* string first_name */ 2:
                    message.firstName = reader.string();
                    break;
                case /* string second_name */ 3:
                    message.secondName = reader.string();
                    break;
                case /* string nick_name */ 4:
                    message.nickName = reader.string();
                    break;
                case /* string url */ 5:
                    message.url = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Profile, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 id = 1; */
        if (message.id !== 0n)
            writer.tag(1, WireType.Varint).int64(message.id);
        /* string first_name = 2; */
        if (message.firstName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.firstName);
        /* string second_name = 3; */
        if (message.secondName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.secondName);
        /* string nick_name = 4; */
        if (message.nickName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.nickName);
        /* string url = 5; */
        if (message.url !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.url);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message foodhunt.admin.v1.Profile
 */
export const Profile = new Profile$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RestaurantFoodFeedback$Type extends MessageType<RestaurantFoodFeedback> {
    constructor() {
        super("foodhunt.admin.v1.RestaurantFoodFeedback", [
            { no: 1, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "food_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 3, name: "point_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 4, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "rating", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 7, name: "urls", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "Profile", kind: "message", jsonName: "Profile", T: () => Profile },
            { no: 9, name: "updated", kind: "message", T: () => Timestamp },
            { no: 10, name: "status", kind: "enum", T: () => ["foodhunt.admin.v1.RestaurantFoodFeedbackStatus", RestaurantFoodFeedbackStatus] }
        ]);
    }
    create(value?: PartialMessage<RestaurantFoodFeedback>): RestaurantFoodFeedback {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0n;
        message.foodId = 0n;
        message.pointId = 0n;
        message.name = "";
        message.description = "";
        message.rating = 0;
        message.urls = [];
        message.status = 0;
        if (value !== undefined)
            reflectionMergePartial<RestaurantFoodFeedback>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RestaurantFoodFeedback): RestaurantFoodFeedback {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 id */ 1:
                    message.id = reader.int64().toBigInt();
                    break;
                case /* int64 food_id */ 2:
                    message.foodId = reader.int64().toBigInt();
                    break;
                case /* int64 point_id */ 3:
                    message.pointId = reader.int64().toBigInt();
                    break;
                case /* string name */ 4:
                    message.name = reader.string();
                    break;
                case /* string description */ 5:
                    message.description = reader.string();
                    break;
                case /* double rating */ 6:
                    message.rating = reader.double();
                    break;
                case /* repeated string urls */ 7:
                    message.urls.push(reader.string());
                    break;
                case /* foodhunt.admin.v1.Profile Profile = 8 [json_name = "Profile"];*/ 8:
                    message.profile = Profile.internalBinaryRead(reader, reader.uint32(), options, message.profile);
                    break;
                case /* google.protobuf.Timestamp updated */ 9:
                    message.updated = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updated);
                    break;
                case /* foodhunt.admin.v1.RestaurantFoodFeedbackStatus status */ 10:
                    message.status = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RestaurantFoodFeedback, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 id = 1; */
        if (message.id !== 0n)
            writer.tag(1, WireType.Varint).int64(message.id);
        /* int64 food_id = 2; */
        if (message.foodId !== 0n)
            writer.tag(2, WireType.Varint).int64(message.foodId);
        /* int64 point_id = 3; */
        if (message.pointId !== 0n)
            writer.tag(3, WireType.Varint).int64(message.pointId);
        /* string name = 4; */
        if (message.name !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.name);
        /* string description = 5; */
        if (message.description !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.description);
        /* double rating = 6; */
        if (message.rating !== 0)
            writer.tag(6, WireType.Bit64).double(message.rating);
        /* repeated string urls = 7; */
        for (let i = 0; i < message.urls.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.urls[i]);
        /* foodhunt.admin.v1.Profile Profile = 8 [json_name = "Profile"]; */
        if (message.profile)
            Profile.internalBinaryWrite(message.profile, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated = 9; */
        if (message.updated)
            Timestamp.internalBinaryWrite(message.updated, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* foodhunt.admin.v1.RestaurantFoodFeedbackStatus status = 10; */
        if (message.status !== 0)
            writer.tag(10, WireType.Varint).int32(message.status);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message foodhunt.admin.v1.RestaurantFoodFeedback
 */
export const RestaurantFoodFeedback = new RestaurantFoodFeedback$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRestaurantPointByIdRequest$Type extends MessageType<GetRestaurantPointByIdRequest> {
    constructor() {
        super("foodhunt.admin.v1.GetRestaurantPointByIdRequest", [
            { no: 1, name: "point_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<GetRestaurantPointByIdRequest>): GetRestaurantPointByIdRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.pointId = 0n;
        if (value !== undefined)
            reflectionMergePartial<GetRestaurantPointByIdRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRestaurantPointByIdRequest): GetRestaurantPointByIdRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 point_id */ 1:
                    message.pointId = reader.int64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRestaurantPointByIdRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 point_id = 1; */
        if (message.pointId !== 0n)
            writer.tag(1, WireType.Varint).int64(message.pointId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message foodhunt.admin.v1.GetRestaurantPointByIdRequest
 */
export const GetRestaurantPointByIdRequest = new GetRestaurantPointByIdRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRestaurantPointByIdResponse$Type extends MessageType<GetRestaurantPointByIdResponse> {
    constructor() {
        super("foodhunt.admin.v1.GetRestaurantPointByIdResponse", [
            { no: 1, name: "item", kind: "message", T: () => RestaurantPoint }
        ]);
    }
    create(value?: PartialMessage<GetRestaurantPointByIdResponse>): GetRestaurantPointByIdResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetRestaurantPointByIdResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRestaurantPointByIdResponse): GetRestaurantPointByIdResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* foodhunt.admin.v1.RestaurantPoint item */ 1:
                    message.item = RestaurantPoint.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRestaurantPointByIdResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* foodhunt.admin.v1.RestaurantPoint item = 1; */
        if (message.item)
            RestaurantPoint.internalBinaryWrite(message.item, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message foodhunt.admin.v1.GetRestaurantPointByIdResponse
 */
export const GetRestaurantPointByIdResponse = new GetRestaurantPointByIdResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRestaurantFoodFeedbackByIdRequest$Type extends MessageType<GetRestaurantFoodFeedbackByIdRequest> {
    constructor() {
        super("foodhunt.admin.v1.GetRestaurantFoodFeedbackByIdRequest", [
            { no: 1, name: "feedback_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<GetRestaurantFoodFeedbackByIdRequest>): GetRestaurantFoodFeedbackByIdRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.feedbackId = 0n;
        if (value !== undefined)
            reflectionMergePartial<GetRestaurantFoodFeedbackByIdRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRestaurantFoodFeedbackByIdRequest): GetRestaurantFoodFeedbackByIdRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 feedback_id */ 1:
                    message.feedbackId = reader.int64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRestaurantFoodFeedbackByIdRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 feedback_id = 1; */
        if (message.feedbackId !== 0n)
            writer.tag(1, WireType.Varint).int64(message.feedbackId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message foodhunt.admin.v1.GetRestaurantFoodFeedbackByIdRequest
 */
export const GetRestaurantFoodFeedbackByIdRequest = new GetRestaurantFoodFeedbackByIdRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRestaurantFoodFeedbackByIdResponse$Type extends MessageType<GetRestaurantFoodFeedbackByIdResponse> {
    constructor() {
        super("foodhunt.admin.v1.GetRestaurantFoodFeedbackByIdResponse", [
            { no: 1, name: "item", kind: "message", T: () => RestaurantFoodFeedback }
        ]);
    }
    create(value?: PartialMessage<GetRestaurantFoodFeedbackByIdResponse>): GetRestaurantFoodFeedbackByIdResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetRestaurantFoodFeedbackByIdResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRestaurantFoodFeedbackByIdResponse): GetRestaurantFoodFeedbackByIdResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* foodhunt.admin.v1.RestaurantFoodFeedback item */ 1:
                    message.item = RestaurantFoodFeedback.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRestaurantFoodFeedbackByIdResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* foodhunt.admin.v1.RestaurantFoodFeedback item = 1; */
        if (message.item)
            RestaurantFoodFeedback.internalBinaryWrite(message.item, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message foodhunt.admin.v1.GetRestaurantFoodFeedbackByIdResponse
 */
export const GetRestaurantFoodFeedbackByIdResponse = new GetRestaurantFoodFeedbackByIdResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AttachFileToRestaurantRequest$Type extends MessageType<AttachFileToRestaurantRequest> {
    constructor() {
        super("foodhunt.admin.v1.AttachFileToRestaurantRequest", [
            { no: 1, name: "restaurant_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "file_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<AttachFileToRestaurantRequest>): AttachFileToRestaurantRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.restaurantId = 0n;
        message.fileId = 0n;
        if (value !== undefined)
            reflectionMergePartial<AttachFileToRestaurantRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AttachFileToRestaurantRequest): AttachFileToRestaurantRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 restaurant_id */ 1:
                    message.restaurantId = reader.int64().toBigInt();
                    break;
                case /* int64 file_id */ 2:
                    message.fileId = reader.int64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AttachFileToRestaurantRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 restaurant_id = 1; */
        if (message.restaurantId !== 0n)
            writer.tag(1, WireType.Varint).int64(message.restaurantId);
        /* int64 file_id = 2; */
        if (message.fileId !== 0n)
            writer.tag(2, WireType.Varint).int64(message.fileId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message foodhunt.admin.v1.AttachFileToRestaurantRequest
 */
export const AttachFileToRestaurantRequest = new AttachFileToRestaurantRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AttachFileToRestaurantResponse$Type extends MessageType<AttachFileToRestaurantResponse> {
    constructor() {
        super("foodhunt.admin.v1.AttachFileToRestaurantResponse", []);
    }
    create(value?: PartialMessage<AttachFileToRestaurantResponse>): AttachFileToRestaurantResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<AttachFileToRestaurantResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AttachFileToRestaurantResponse): AttachFileToRestaurantResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AttachFileToRestaurantResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message foodhunt.admin.v1.AttachFileToRestaurantResponse
 */
export const AttachFileToRestaurantResponse = new AttachFileToRestaurantResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateRestaurantRequest$Type extends MessageType<UpdateRestaurantRequest> {
    constructor() {
        super("foodhunt.admin.v1.UpdateRestaurantRequest", [
            { no: 1, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "file_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 5, name: "type", kind: "enum", T: () => ["foodhunt.admin.v1.RestaurantType", RestaurantType] },
            { no: 6, name: "status", kind: "enum", T: () => ["foodhunt.admin.v1.RestaurantStatus", RestaurantStatus] }
        ]);
    }
    create(value?: PartialMessage<UpdateRestaurantRequest>): UpdateRestaurantRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0n;
        message.name = "";
        message.fileId = 0n;
        message.type = 0;
        message.status = 0;
        if (value !== undefined)
            reflectionMergePartial<UpdateRestaurantRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateRestaurantRequest): UpdateRestaurantRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 id */ 1:
                    message.id = reader.int64().toBigInt();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* int64 file_id */ 4:
                    message.fileId = reader.int64().toBigInt();
                    break;
                case /* foodhunt.admin.v1.RestaurantType type */ 5:
                    message.type = reader.int32();
                    break;
                case /* foodhunt.admin.v1.RestaurantStatus status */ 6:
                    message.status = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateRestaurantRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 id = 1; */
        if (message.id !== 0n)
            writer.tag(1, WireType.Varint).int64(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* int64 file_id = 4; */
        if (message.fileId !== 0n)
            writer.tag(4, WireType.Varint).int64(message.fileId);
        /* foodhunt.admin.v1.RestaurantType type = 5; */
        if (message.type !== 0)
            writer.tag(5, WireType.Varint).int32(message.type);
        /* foodhunt.admin.v1.RestaurantStatus status = 6; */
        if (message.status !== 0)
            writer.tag(6, WireType.Varint).int32(message.status);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message foodhunt.admin.v1.UpdateRestaurantRequest
 */
export const UpdateRestaurantRequest = new UpdateRestaurantRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateRestaurantResponse$Type extends MessageType<UpdateRestaurantResponse> {
    constructor() {
        super("foodhunt.admin.v1.UpdateRestaurantResponse", []);
    }
    create(value?: PartialMessage<UpdateRestaurantResponse>): UpdateRestaurantResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UpdateRestaurantResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateRestaurantResponse): UpdateRestaurantResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateRestaurantResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message foodhunt.admin.v1.UpdateRestaurantResponse
 */
export const UpdateRestaurantResponse = new UpdateRestaurantResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateRestaurantPointRequest$Type extends MessageType<UpdateRestaurantPointRequest> {
    constructor() {
        super("foodhunt.admin.v1.UpdateRestaurantPointRequest", [
            { no: 1, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "restaurant_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "type", kind: "enum", T: () => ["foodhunt.admin.v1.RestaurantType", RestaurantType] },
            { no: 6, name: "latitude", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 7, name: "longitude", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 8, name: "rating", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 9, name: "review_count", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 10, name: "distance", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 11, name: "status", kind: "enum", T: () => ["foodhunt.admin.v1.RestaurantPointStatus", RestaurantPointStatus] }
        ]);
    }
    create(value?: PartialMessage<UpdateRestaurantPointRequest>): UpdateRestaurantPointRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0n;
        message.restaurantId = 0n;
        message.name = "";
        message.url = "";
        message.type = 0;
        message.latitude = 0;
        message.longitude = 0;
        message.rating = 0;
        message.reviewCount = 0n;
        message.distance = 0n;
        message.status = 0;
        if (value !== undefined)
            reflectionMergePartial<UpdateRestaurantPointRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateRestaurantPointRequest): UpdateRestaurantPointRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 id */ 1:
                    message.id = reader.int64().toBigInt();
                    break;
                case /* int64 restaurant_id */ 2:
                    message.restaurantId = reader.int64().toBigInt();
                    break;
                case /* string name */ 3:
                    message.name = reader.string();
                    break;
                case /* string url */ 4:
                    message.url = reader.string();
                    break;
                case /* foodhunt.admin.v1.RestaurantType type */ 5:
                    message.type = reader.int32();
                    break;
                case /* double latitude */ 6:
                    message.latitude = reader.double();
                    break;
                case /* double longitude */ 7:
                    message.longitude = reader.double();
                    break;
                case /* double rating */ 8:
                    message.rating = reader.double();
                    break;
                case /* int64 review_count */ 9:
                    message.reviewCount = reader.int64().toBigInt();
                    break;
                case /* int64 distance */ 10:
                    message.distance = reader.int64().toBigInt();
                    break;
                case /* foodhunt.admin.v1.RestaurantPointStatus status */ 11:
                    message.status = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateRestaurantPointRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 id = 1; */
        if (message.id !== 0n)
            writer.tag(1, WireType.Varint).int64(message.id);
        /* int64 restaurant_id = 2; */
        if (message.restaurantId !== 0n)
            writer.tag(2, WireType.Varint).int64(message.restaurantId);
        /* string name = 3; */
        if (message.name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.name);
        /* string url = 4; */
        if (message.url !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.url);
        /* foodhunt.admin.v1.RestaurantType type = 5; */
        if (message.type !== 0)
            writer.tag(5, WireType.Varint).int32(message.type);
        /* double latitude = 6; */
        if (message.latitude !== 0)
            writer.tag(6, WireType.Bit64).double(message.latitude);
        /* double longitude = 7; */
        if (message.longitude !== 0)
            writer.tag(7, WireType.Bit64).double(message.longitude);
        /* double rating = 8; */
        if (message.rating !== 0)
            writer.tag(8, WireType.Bit64).double(message.rating);
        /* int64 review_count = 9; */
        if (message.reviewCount !== 0n)
            writer.tag(9, WireType.Varint).int64(message.reviewCount);
        /* int64 distance = 10; */
        if (message.distance !== 0n)
            writer.tag(10, WireType.Varint).int64(message.distance);
        /* foodhunt.admin.v1.RestaurantPointStatus status = 11; */
        if (message.status !== 0)
            writer.tag(11, WireType.Varint).int32(message.status);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message foodhunt.admin.v1.UpdateRestaurantPointRequest
 */
export const UpdateRestaurantPointRequest = new UpdateRestaurantPointRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateRestaurantPointResponse$Type extends MessageType<UpdateRestaurantPointResponse> {
    constructor() {
        super("foodhunt.admin.v1.UpdateRestaurantPointResponse", []);
    }
    create(value?: PartialMessage<UpdateRestaurantPointResponse>): UpdateRestaurantPointResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UpdateRestaurantPointResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateRestaurantPointResponse): UpdateRestaurantPointResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateRestaurantPointResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message foodhunt.admin.v1.UpdateRestaurantPointResponse
 */
export const UpdateRestaurantPointResponse = new UpdateRestaurantPointResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateRestaurantFoodRequest$Type extends MessageType<UpdateRestaurantFoodRequest> {
    constructor() {
        super("foodhunt.admin.v1.UpdateRestaurantFoodRequest", [
            { no: 1, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "restaurant_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "rating", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 7, name: "review_count", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 8, name: "status", kind: "enum", T: () => ["foodhunt.admin.v1.RestaurantFoodStatus", RestaurantFoodStatus] }
        ]);
    }
    create(value?: PartialMessage<UpdateRestaurantFoodRequest>): UpdateRestaurantFoodRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0n;
        message.restaurantId = 0n;
        message.name = "";
        message.url = "";
        message.rating = 0;
        message.reviewCount = 0n;
        message.status = 0;
        if (value !== undefined)
            reflectionMergePartial<UpdateRestaurantFoodRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateRestaurantFoodRequest): UpdateRestaurantFoodRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 id */ 1:
                    message.id = reader.int64().toBigInt();
                    break;
                case /* int64 restaurant_id */ 2:
                    message.restaurantId = reader.int64().toBigInt();
                    break;
                case /* string name */ 3:
                    message.name = reader.string();
                    break;
                case /* string url */ 5:
                    message.url = reader.string();
                    break;
                case /* double rating */ 6:
                    message.rating = reader.double();
                    break;
                case /* int64 review_count */ 7:
                    message.reviewCount = reader.int64().toBigInt();
                    break;
                case /* foodhunt.admin.v1.RestaurantFoodStatus status */ 8:
                    message.status = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateRestaurantFoodRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 id = 1; */
        if (message.id !== 0n)
            writer.tag(1, WireType.Varint).int64(message.id);
        /* int64 restaurant_id = 2; */
        if (message.restaurantId !== 0n)
            writer.tag(2, WireType.Varint).int64(message.restaurantId);
        /* string name = 3; */
        if (message.name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.name);
        /* string url = 5; */
        if (message.url !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.url);
        /* double rating = 6; */
        if (message.rating !== 0)
            writer.tag(6, WireType.Bit64).double(message.rating);
        /* int64 review_count = 7; */
        if (message.reviewCount !== 0n)
            writer.tag(7, WireType.Varint).int64(message.reviewCount);
        /* foodhunt.admin.v1.RestaurantFoodStatus status = 8; */
        if (message.status !== 0)
            writer.tag(8, WireType.Varint).int32(message.status);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message foodhunt.admin.v1.UpdateRestaurantFoodRequest
 */
export const UpdateRestaurantFoodRequest = new UpdateRestaurantFoodRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateRestaurantFoodResponse$Type extends MessageType<UpdateRestaurantFoodResponse> {
    constructor() {
        super("foodhunt.admin.v1.UpdateRestaurantFoodResponse", []);
    }
    create(value?: PartialMessage<UpdateRestaurantFoodResponse>): UpdateRestaurantFoodResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UpdateRestaurantFoodResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateRestaurantFoodResponse): UpdateRestaurantFoodResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateRestaurantFoodResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message foodhunt.admin.v1.UpdateRestaurantFoodResponse
 */
export const UpdateRestaurantFoodResponse = new UpdateRestaurantFoodResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRestaurantByIdRequest$Type extends MessageType<GetRestaurantByIdRequest> {
    constructor() {
        super("foodhunt.admin.v1.GetRestaurantByIdRequest", [
            { no: 1, name: "restaurant_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<GetRestaurantByIdRequest>): GetRestaurantByIdRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.restaurantId = 0n;
        if (value !== undefined)
            reflectionMergePartial<GetRestaurantByIdRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRestaurantByIdRequest): GetRestaurantByIdRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 restaurant_id */ 1:
                    message.restaurantId = reader.int64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRestaurantByIdRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 restaurant_id = 1; */
        if (message.restaurantId !== 0n)
            writer.tag(1, WireType.Varint).int64(message.restaurantId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message foodhunt.admin.v1.GetRestaurantByIdRequest
 */
export const GetRestaurantByIdRequest = new GetRestaurantByIdRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRestaurantByIdResponse$Type extends MessageType<GetRestaurantByIdResponse> {
    constructor() {
        super("foodhunt.admin.v1.GetRestaurantByIdResponse", [
            { no: 1, name: "item", kind: "message", T: () => RestaurantSearch }
        ]);
    }
    create(value?: PartialMessage<GetRestaurantByIdResponse>): GetRestaurantByIdResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetRestaurantByIdResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRestaurantByIdResponse): GetRestaurantByIdResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* foodhunt.admin.v1.RestaurantSearch item */ 1:
                    message.item = RestaurantSearch.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRestaurantByIdResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* foodhunt.admin.v1.RestaurantSearch item = 1; */
        if (message.item)
            RestaurantSearch.internalBinaryWrite(message.item, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message foodhunt.admin.v1.GetRestaurantByIdResponse
 */
export const GetRestaurantByIdResponse = new GetRestaurantByIdResponse$Type();
/**
 * @generated ServiceType for protobuf service foodhunt.admin.v1.AdminAPI
 */
export const AdminAPI = new ServiceType("foodhunt.admin.v1.AdminAPI", [
    { name: "AcceptRestaurant", options: {}, I: AcceptRestaurantRequest, O: AcceptRestaurantResponse },
    { name: "AcceptRestaurantPoint", options: {}, I: AcceptRestaurantPointRequest, O: AcceptRestaurantPointResponse },
    { name: "AcceptRestaurantFood", options: {}, I: AcceptRestaurantFoodRequest, O: AcceptRestaurantFoodResponse },
    { name: "AcceptRestaurantFoodFeedback", options: {}, I: AcceptRestaurantFoodFeedbackdRequest, O: AcceptRestaurantFoodFeedbackResponse },
    { name: "GetRestaurants", options: {}, I: GetRestaurantsRequest, O: GetRestaurantsResponse },
    { name: "GetRestaurantById", options: {}, I: GetRestaurantByIdRequest, O: GetRestaurantByIdResponse },
    { name: "GetRestaurantPoints", options: {}, I: GetRestaurantPointsRequest, O: GetRestaurantPointsResponse },
    { name: "GetRestaurantFoods", options: {}, I: GetRestaurantFoodsRequest, O: GetRestaurantFoodsResponse },
    { name: "GetRestaurantFoodFeedbacks", options: {}, I: GetRestaurantFoodFeedbacksRequest, O: GetRestaurantFoodFeedbacksResponse },
    { name: "GetRestaurantPointById", options: {}, I: GetRestaurantPointByIdRequest, O: GetRestaurantPointByIdResponse },
    { name: "GetRestaurantFoodById", options: {}, I: GetRestaurantFoodByIdRequest, O: GetRestaurantFoodByIdResponse },
    { name: "GetRestaurantFoodFeedbackById", options: {}, I: GetRestaurantFoodFeedbackByIdRequest, O: GetRestaurantFoodFeedbackByIdResponse },
    { name: "AttachFileToRestaurant", options: {}, I: AttachFileToRestaurantRequest, O: AttachFileToRestaurantResponse },
    { name: "UpdateRestaurant", options: {}, I: UpdateRestaurantRequest, O: UpdateRestaurantResponse },
    { name: "UpdateRestaurantPoint", options: {}, I: UpdateRestaurantPointRequest, O: UpdateRestaurantPointResponse },
    { name: "UpdateRestaurantFood", options: {}, I: UpdateRestaurantFoodRequest, O: UpdateRestaurantFoodResponse }
]);
