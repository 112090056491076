import AccessToken from 'domain/entity/session/models/AccessToken'
import RefreshToken from 'domain/entity/session/models/RefreshToken'

export default class SessionRepository {
	private readonly ACCESS_TOKEN = 'access_token'
	private readonly REFRESH_TOKEN = 'refresh_token'

	private localStorage: Storage

	constructor() {
		this.localStorage = window.localStorage
	}

	public getAccessToken(): AccessToken | null {
		const accessTokenRaw = this.getAccessTokenRaw()
		if (accessTokenRaw == null) {
			return null
		}
		return new AccessToken(accessTokenRaw)
	}

	public saveAccessToken(accessToken: AccessToken): void {
		this.saveAccessTokenRaw(accessToken.getRaw())
	}

	public saveRefreshToken(refreshToken: RefreshToken): void {
		this.saveRefreshTokenRaw(refreshToken.getRaw())
	}

	public getAccessTokenRaw(): string | null {
		return this.localStorage.getItem(this.ACCESS_TOKEN)
	}

	public getRefreshTokenRaw(): string | null {
		return this.localStorage.getItem(this.REFRESH_TOKEN)
	}

	private saveAccessTokenRaw(token: string): void {
		this.localStorage.setItem(this.ACCESS_TOKEN, token)
	}

	private saveRefreshTokenRaw(token: string): void {
		this.localStorage.setItem(this.REFRESH_TOKEN, token)
	}

	destroyToken(): void {
		this.localStorage.removeItem(this.ACCESS_TOKEN)
		this.localStorage.removeItem(this.REFRESH_TOKEN)
	}
}
