import React from 'react'

export interface ImageProfileProps {
	url: string | undefined
}

export default function ImageProfile({ url }: ImageProfileProps) {
	let image_url = url
	if (image_url == undefined || image_url.length <= 0) {
		image_url = '/image/placeholder_large.jpg'
	}

	return <img className='w-12 h-12 me-3 object-cover rounded-full' src={image_url} alt='' />
}
