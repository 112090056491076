import { RestaurantStatus, RestaurantType } from 'grpc/gen/admin/v1/admin'

function formatRestaurantType(type: RestaurantType): string {
	switch (type) {
		case 0:
			return 'Невалидно'
		case 1:
			return 'Ресторан'
		case 2:
			return 'Кафе'
		case 3:
			return 'Бар'
		case 4:
			return 'Пекарня'
		case 5:
			return 'Столовая'
		case 6:
			return 'Быстрая еда'
		case 7:
			return 'Пиццерия'
	}
	return 'Не известно'
}

function formatRestaurantStatus(status: RestaurantStatus): string {
	switch (status) {
		case 0:
			return 'Создано'
		case 1:
			return 'На модерации'
		case 2:
			return 'Опубликовано'
	}
	return 'Не известно'
}

export { formatRestaurantType, formatRestaurantStatus }
