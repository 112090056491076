import React, { useEffect } from 'react'
import NavBarView from 'presentation/view/navigation/NavigationBarView'
import AdminRepository from 'domain/repository/admin/AdminRepository'
import { useParams } from 'react-router-dom'
import FeedbackViewModel from 'presentation/viewmodel/feedback/FeedbackViewModel'
import AdminUseCase from 'domain/usecase/admin/AdminUseCase'
import FeedbackPointComponent from './FeedbackPointComponent'
import FeedbackFoodComponent from './FeedbackFoodComponent'
import FeedbackComponent from './FeedbackComponent'

export default function FeedbackView() {
	const adminUseCase = new AdminUseCase(new AdminRepository())
	const viewModel = new FeedbackViewModel(adminUseCase)
	const { feedbackId } = useParams()

	useEffect(() => {
		const dataFetch = async () => {
			const feedback = await viewModel.fetchFeedback(Number(feedbackId))
			if (feedback != null) {
				await viewModel.fetchRestaurantPoint(Number(feedback.pointId))
				await viewModel.fetchRestaurantFood(Number(feedback.foodId))
			}
		}
		dataFetch()
	}, [])

	return (
		<div>
			<NavBarView />
			<div>{feedbackId}</div>
			<FeedbackPointComponent viewModel={viewModel} />
			<FeedbackFoodComponent viewModel={viewModel} />
			<FeedbackComponent viewModel={viewModel} />
		</div>
	)
}
